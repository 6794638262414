const additionalZombiesRadioList = [
  {
    text: 'Да',
    value: true,
  },
  {
    text: 'Нет',
    value: false,
  },
]

export default additionalZombiesRadioList
