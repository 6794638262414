const encounterByBiomeList = {
  beach: dice => {
    switch (dice) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        return 'aarakokry'
      case 8:
        return 'artus_simber'
      case 9:
      case 10:
        return 'ekspeditcionnyi_zapas'
      case 11:
      case 12:
        return 'chvinga'
      case 13:
      case 14:
        return 'dinozavry_allozavr'
      case 15:
      case 16:
        return 'dinozavry_dimetrodon'
      case 17:
      case 18:
      case 19:
      case 20:
      case 21:
        return 'dinozavry_pleziozavr'
      case 22:
      case 23:
      case 24:
      case 25:
      case 26:
      case 27:
      case 28:
        return 'dinozavry_pteranodon'
      case 29:
      case 30:
      case 31:
        return 'dinozavry_kettcalkoatl'
      case 32:
      case 33:
      case 34:
      case 35:
      case 36:
      case 37:
        return 'dinozavry_velotciraptor'
      case 38:
      case 39:
      case 40:
        return 'drakon_krasnyi'
      case 41:
      case 42:
        return 'izumrudnyi_anclav'
      case 43:
      case 44:
      case 45:
      case 46:
        return 'issledovateli'
      case 47:
      case 48:
      case 49:
        return 'pylaiushchii_kulak'
      case 50:
      case 51:
      case 52:
        return 'letaiushchie_obeziany'
      case 53:
      case 54:
      case 55:
        return 'letaiushchie_zmei'
      case 56:
      case 57:
        return 'ledianye_velikany'
      case 58:
      case 59:
      case 60:
      case 61:
      case 62:
      case 63:
        return 'gigantskie_iashcheritcy'
      case 64:
      case 65:
      case 66:
      case 67:
        return 'gigantskaia_shchyolkaiushchaia_cherepaha'
      case 68:
      case 69:
      case 70:
      case 71:
        return 'liudoiashchery'
      case 72:
      case 73:
      case 74:
        return 'krasnyi_volshebnik'
      case 75:
      case 76:
      case 77:
      case 78:
      case 79:
      case 80:
      case 81:
      case 82:
      case 83:
      case 84:
        return 'morskaia_karga'
      case 85:
      case 86:
      case 87:
        return 'krovopiitcy'
      case 88:
      case 89:
        return 'roi_letuchikh_myshei'
      case 90:
      case 91:
      case 92:
      case 93:
      case 94:
        return 'tabaksi_ohotneyk'
      case 95:
      case 96:
      case 97:
      case 98:
      case 99:
      case 0:
      default:
        return 'vaiia_trekhsotcvetnaia'
    }
  },
  jungle_undead_clear: dice => {
    switch (dice) {
      case 1:
        return 'dvarfy_albinos'
      case 2:
        return 'almirazh'
      case 3:
      case 4:
        return 'chelovekoobraznye_obeziany'
      case 5:
        return 'artus_simber'
      case 6:
      case 7:
        return 'lozy_ubiitcy'
      case 8:
        return 'toporocliuvy'
      case 9:
        return 'babuiny'
      case 10:
      case 11:
        return 'ekspeditcionnyi_zapas'
      case 12:
      case 13:
        return 'kannibaly'
      case 14:
      case 15:
        return 'chvinga'
      case 16:
        return 'tciclopy'
      case 17:
        return 'dinozavry_allozavr'
      case 18:
        return 'dinozavry_ankilozavr'
      case 19:
        return 'dinozavry_brontozavr'
      case 20:
      case 21:
        return 'dinozavry_deinonikh'
      case 22:
      case 23:
        return 'dinozavry_gadrozavr'
      case 24:
        return 'dinozavry_pteranodon'
      case 25:
      case 26:
        return 'dinozavry_stegozavr'
      case 27:
      case 28:
        return 'dinozavry_tritceratops'
      case 29:
      case 30:
        return 'dinozavry_tirannozavr'
      case 31:
      case 32:
      case 33:
      case 34:
      case 35:
        return 'dinozavry_velotciraptor'
      case 36:
        return 'drakon_volshebnyi_drakonchik'
      case 37:
        return 'eblis'
      case 38:
      case 39:
      case 40:
      case 41:
      case 42:
        return 'izumrudnyi_anclav'
      case 43:
      case 44:
        return 'issledovatel_myortvyi'
      case 45:
        return 'issledovateli'
      case 46:
        return 'tcepovaia_ulitka'
      case 47:
      case 48:
      case 49:
      case 50:
        return 'pylaiushchii_kulak'
      case 51:
        return 'letaiushchie_obeziany'
      case 52:
      case 53:
        return 'letaiushchie_zmei'
      case 54:
      case 55:
        return 'ledianye_velikany'
      case 56:
        return 'gigantskie_kabany'
      case 57:
        return 'gigantskie_liagushki'
      case 58:
        return 'gigantskie_iashcheritcy'
      case 59:
        return 'gigantskie_skorpiony'
      case 60:
        return 'gigantskie_osy'
      case 61:
      case 62:
        return 'girallony'
      case 63:
      case 64:
        return 'gobliny'
      case 65:
      case 66:
        return 'grungi'
      case 67:
        return 'dzhakuli'
      case 68:
        return 'kamadany'
      case 69:
      case 70:
        return 'liudoiashchery'
      case 71:
      case 72:
        return 'tuman_bezumnoi_obeziany'
      case 73:
        return 'kapkany'
      case 74:
        return 'nochnaia_karga'
      case 75:
        return 'pteraliudy'
      case 76:
        return 'redkoe_rastenie'
      case 77:
        return 'krasnyi_volshebnik'
      case 78:
      case 79:
        return 'zmeia_udav'
      case 80:
        return 'zmeia_gigantskii_udav'
      case 81:
        return 'zmeia_gigantskaia_iadovitaia'
      case 82:
        return 'pauki'
      case 83:
      case 84:
      case 85:
        return 'statuia_ubtao'
      case 86:
        return 'krovopiitcy'
      case 87:
        return 'su_monstry'
      case 88:
        return 'roi_letuchikh_myshei'
      case 89:
        return 'roi_nasekomykh'
      case 90:
        return 'tabaksi_ohotneyk'
      case 91:
        return 'tigr'
      case 92:
        return 'vaiia_trekhsotcvetnaia'
      case 93:
        return 'vegepigmei'
      case 94:
        return 'vervepr'
      case 95:
        return 'vertigr'
      case 96:
        return 'zimnii_peizazh'
      case 97:
        return 'zhyoltaia_muskusnaia_liana_i_zombi'
      case 98:
        return 'iuan_ti'
      case 99:
        return 'zhentarim'
      case 0:
      default:
        return 'zorbo'
    }
  },
  jungle_undead_few: dice => {
    switch (dice) {
      case 2:
        return 'artus_simber'
      case 66:
        return 'vaiia_trekhsotcvetnaia'
      case 90:
      case 91:
        return 'vegepigmei'
      case 92:
        return 'vervepr'
      case 93:
        return 'vertigr'
      case 29:
        return 'gigantskie_osy'
      case 28:
        return 'gigantskie_iashcheritcy'
      case 30:
      case 31:
        return 'girallony'
      case 32:
      case 33:
        return 'gobliny'
      case 34:
      case 35:
        return 'grungi'
      case 1:
        return 'dvarfy_albinos'
      case 11:
        return 'dinozavry_allozavr'
      case 12:
        return 'dinozavry_ankilozavr'
      case 14:
        return 'dinozavry_gadrozavr'
      case 13:
        return 'dinozavry_deinonikh'
      case 15:
        return 'dinozavry_pteranodon'
      case 16:
        return 'dinozavry_stegozavr'
      case 18:
        return 'dinozavry_tirannozavr'
      case 17:
        return 'dinozavry_tritceratops'
      case 95:
      case 96:
        return 'zhyoltaia_muskusnaia_liana_i_zombi'
      case 99:
        return 'zhentarim'
      case 94:
        return 'zimnii_peizazh'
      case 50:
        return 'zmeia_gigantskaia_iadovitaia '
      case 49:
        return 'zmeia_gigantskii_udav'
      case 47:
      case 48:
        return 'zmeia_udav'
      case 0:
      default:
        return 'zorbo'
      case 19:
      case 20:
        return 'izumrudnyi_anclav'
      case 23:
        return 'issledovateli'
      case 21:
      case 22:
        return 'issledovatel_myortvyi'
      case 9:
      case 10:
        return 'kannibaly'
      case 40:
      case 41:
        return 'kapkany'
      case 46:
        return 'krasnyi_volshebnik'
      case 56:
      case 57:
        return 'krovopiitcy'
      case 27:
        return 'letaiushchie_zmei'
      case 3:
      case 4:
      case 5:
        return 'lozy_ubiitcy'
      case 81:
      case 82:
      case 83:
      case 84:
      case 85:
      case 86:
      case 87:
      case 88:
      case 89:
        return 'nezhit_zombi'
      case 73:
      case 74:
      case 75:
      case 76:
      case 77:
        return 'nezhit_skelety`'
      case 78:
      case 79:
        return 'nezhit_spektry`'
      case 80:
        return 'nezhit_umertvie'
      case 68:
      case 69:
      case 70:
      case 71:
      case 72:
        return 'nezhit_upyri'
      case 42:
        return 'nochnaia_karga'
      case 51:
      case 52:
        return 'pauki'
      case 43:
      case 44:
        return 'pteraliudy`'
      case 24:
      case 25:
      case 26:
        return 'pylaiushchii_kulak'
      case 45:
        return 'redkoe_rastenie'
      case 60:
      case 61:
      case 62:
        return 'roi_letuchikh_myshei`'
      case 63:
      case 64:
      case 65:
        return 'roi_nasekomykh'
      case 53:
      case 54:
      case 55:
        return 'statuia_ubtao'
      case 58:
      case 59:
        return 'su_monstry'
      case 6:
        return 'toporocliuvy`'
      case 67:
        return 'troll'
      case 36:
      case 37:
      case 38:
      case 39:
        return 'tuman_bezumnoi_obeziany'
      case 7:
      case 8:
        return 'ekspeditcionnyi_zapas'
      case 97:
      case 98:
        return 'iuan_ti'
    }
  },
  jungle_undead_many: dice => {
    switch (dice) {
      case 1:
        return 'artus_simber'
      case 50 :
        return 'vaiia_trekhsotcvetnaia'
      case 86:
      case 87:
        return 'vegepigmei'
      case 88:
      case 89:
        return 'vervepr'
      case 90:
      case 91:
        return 'vertigr'
      case 24:
        return 'gigantskie_osy'
      case 6:
        return 'dinozavry_allozavr'
      case 7:
        return 'dinozavry_ankilozavr'
      case 13:
      case 14:
        return 'dinozavry_velotciraptor'
      case 8:
        return 'dinozavry_gadrozavr'
      case 9:
        return 'dinozavry_pteranodon'
      case 10:
        return 'dinozavry_stegozavr'
      case 11:
      case 12:
        return 'dinozavry_tirannozavr'
      case 93:
      case 94:
      case 95:
      case 96:
        return 'zhyoltaia_muskusnaia_liana_i_zombi'
      case 92:
        return 'zimnii_peizazh'
      case 33:
        return 'zmeia_gigantskaia_iadovitaia '
      case 32:
        return 'zmeia_gigantskii_udav'
      case 29:
      case 30:
      case 31:
        return 'zmeia_udav'
      case 99:
      case 0:
      default:
        return 'zorbo'
      case 15:
      case 16:
        return 'izumrudnyi_anclav'
      case 21:
        return 'issledovateli'
      case 17:
      case 18:
      case 19:
      case 20:
        return 'issledovatel_myortvyi'
      case 25:
        return 'kapkany'
      case 28:
        return 'krasnyi_volshebnik'
      case 41:
      case 42:
      case 43:
      case 44:
        return 'krovopiitcy'
      case 2:
        return 'lozy_ubiitcy'
      case 74:
      case 75:
      case 76:
      case 77:
      case 78:
      case 79:
      case 80:
      case 81:
      case 82:
      case 83:
      case 84:
      case 85:
        return 'nezhit_zombi'
      case 64:
      case 65:
      case 66:
      case 67:
        return 'nezhit_skelety`'
      case 68:
      case 69:
      case 70:
        return 'nezhit_spektry`'
      case 71:
      case 72:
      case 73:
        return 'nezhit_umertvie'
      case 52:
      case 53:
      case 54:
      case 55:
      case 56:
      case 57:
      case 58:
      case 59:
      case 60:
      case 61:
      case 62:
      case 63:
        return 'nezhit_upyri'
      case 34:
      case 35:
      case 36:
        return 'pauki'
      case 26:
        return 'pteraliudy`'
      case 22:
      case 23:
        return 'pylaiushchii_kulak'
      case 27:
        return 'redkoe_rastenie'
      case 46:
        return 'roi_letuchikh_myshei`'
      case 47:
      case 48:
      case 49:
        return 'roi_nasekomykh'
      case 37:
      case 38:
      case 39:
      case 40:
        return 'statuia_ubtao'
      case 45:
        return 'su_monstry'
      case 51:
        return 'troll'
      case 3:
      case 4:
      case 5:
        return 'ekspeditcionnyi_zapas'
      case 97:
      case 98:
        return 'iuan_ti'
    }
  },
  mountain: dice => {
    switch (dice) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
        return 'aarakokry'
      case 21:
      case 22:
        return 'babuiny'
      case 98:
      case 99:
      case 0:
      default:
        return 'vervepr'
      case 62:
        return 'gigantskie_kabany'
      case 64:
      case 65:
        return 'gigantskie_osy'
      case 63:
        return 'gigantskie_iashcheritcy'
      case 66:
      case 67:
      case 68:
      case 69:
      case 70:
        return 'girallony'
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
        return 'dvarfy_albinos'
      case 39:
      case 40:
      case 41:
      case 42:
        return 'dinozavry_kettcalkoatl'
      case 30:
      case 31:
      case 32:
      case 33:
      case 34:
      case 35:
      case 36:
      case 37:
      case 38:
        return 'dinozavry_pteranodon'
      case 43:
      case 44:
      case 45:
        return 'drakon_krasnyi'
      case 82:
      case 83:
      case 84:
        return 'zmeia_gigantskaia_iadovitaia '
      case 46:
      case 47:
        return 'izumrudnyi_anclav'
      case 51:
      case 52:
      case 53:
        return 'issledovateli'
      case 48:
      case 49:
      case 50:
        return 'issledovatel_myortvyi'
      case 81:
        return 'krasnyi_volshebnik'
      case 85:
      case 86:
      case 87:
        return 'krovopiitcy'
      case 60:
      case 61:
        return 'letaiushchie_zmei'
      case 54:
      case 55:
      case 56:
      case 57:
      case 58:
      case 59:
        return 'letaiushchie_obeziany`'
      case 71:
      case 72:
      case 73:
        return 'nochnaia_karga'
      case 74:
      case 75:
      case 76:
      case 77:
      case 78:
      case 79:
      case 80:
        return 'pteraliudy`'
      case 88:
      case 89:
      case 90:
        return 'roi_letuchikh_myshei`'
      case 91:
      case 92:
        return 'tabaksi_ohotneyk'
      case 93:
      case 94:
      case 95:
      case 96:
      case 97:
        return 'troll'
      case 28:
      case 29:
        return 'tciclopy`'
      case 26:
      case 27:
        return 'chvinga'
      case 18:
      case 19:
      case 20:
        return 'chelovekoobraznye_obeziany`'
      case 23:
      case 24:
      case 25:
        return 'ekspeditcionnyi_zapas'
    }
  },
  river: dice => {
    switch (dice) {
      case 1:
      case 2:
      case 3:
        return 'aarakokry'
      case 4:
      case 5:
      case 6:
      case 7:
        return 'aldani'
      case 8:
      case 9:
        return 'artus_simber'
      case 61:
      case 62:
        return 'gigantskaia_shchyolkaiushchaia_cherepaha'
      case 59:
      case 60:
        return 'gigantskie_liagushki'
      case 63:
        return 'gigantskie_osy'
      case 56:
      case 57:
      case 58:
        return 'gigantskii_krokodil'
      case 64:
      case 65:
      case 66:
        return 'grungi'
      case 67:
        return 'dzhakuli'
      case 24:
        return 'dinozavry_brontozavr'
      case 27:
      case 28:
        return 'dinozavry_gadrozavr'
      case 25:
      case 26:
        return 'dinozavry_dimetrodon'
      case 35:
      case 36:
        return 'dinozavry_kettcalkoatl'
      case 29:
      case 30:
      case 31:
        return 'dinozavry_pleziozavr'
      case 32:
      case 33:
      case 34:
        return 'dinozavry_pteranodon'
      case 37:
        return 'drakon_volshebnyi_drakonchik'
      case 99:
      case 0:
      default:
        return 'zhentarim'
      case 80:
        return 'zmeia_gigantskii_udav'
      case 77:
      case 78:
      case 79:
        return 'zmeia_udav'
      case 41:
      case 42:
      case 43:
        return 'izumrudnyi_anclav'
      case 46:
      case 47:
      case 48:
      case 49:
        return 'issledovateli'
      case 44:
      case 45:
        return 'issledovatel_myortvyi'
      case 13:
      case 14:
      case 15:
        return 'kannibaly'
      case 74:
        return 'krasnyi_volshebnik'
      case 82:
      case 83:
        return 'krovopiitcy'
      case 19:
      case 20:
      case 21:
      case 22:
      case 23:
        return 'krokodily'
      case 54:
      case 55:
        return 'letaiushchie_zmei'
      case 52:
      case 53:
        return 'letaiushchie_obeziany`'
      case 10:
        return 'lozy_ubiitcy'
      case 68:
        return 'liudoiashchery`'
      case 75:
      case 76:
        return 'morskaia_karga'
      case 96:
        return 'nezhit_zombi'
      case 95:
        return 'nezhit_skelety`'
      case 94:
        return 'nezhit_upyri'
      case 71:
      case 72:
        return 'pteraliudy`'
      case 50:
      case 51:
        return 'pylaiushchii_kulak'
      case 73:
        return 'redkoe_rastenie'
      case 86:
      case 87:
      case 88:
      case 89:
      case 90:
      case 91:
        return 'roi_kviperov'
      case 84:
      case 85:
        return 'roi_nasekomykh'
      case 81:
        return 'statuia_ubtao'
      case 92:
      case 93:
        return 'tabaksi_ohotneyk'
      case 69:
      case 70:
        return 'tuman_bezumnoi_obeziany'
      case 16:
      case 17:
      case 18:
        return 'chvinga'
      case 38:
      case 39:
      case 40:
        return 'eblis'
      case 11:
      case 12:
        return 'ekspeditcionnyi_zapas'
      case 97:
      case 98:
        return 'iuan_ti'
    }
  },
  ruin: dice => {
    switch (dice) {
      case 3:
        return 'almirazh'
      case 7:
      case 8:
        return 'artus_simber'
      case 13:
      case 14:
        return 'babuiny'
      case 79:
      case 80:
        return 'vaiia_trekhsotcvetnaia'
      case 94:
        return 'vertigr'
      case 46:
      case 47:
      case 48:
        return 'gigantskie_osy'
      case 43:
      case 44:
      case 45:
        return 'gigantskie_skorpiony'
      case 42:
        return 'gigantskie_iashcheritcy'
      case 49:
      case 50:
        return 'girallony'
      case 51:
      case 52:
        return 'gobliny'
      case 1:
      case 2:
        return 'dvarfy_albinos'
      case 53:
      case 54:
        return 'dzhakuli'
      case 23:
        return 'dinozavry_velotciraptor'
      case 22:
        return 'dinozavry_deinonikh'
      case 96:
        return 'zhyoltaia_muskusnaia_liana_i_zombi'
      case 99:
      case 0:
      default:
        return 'zhentarim'
      case 95:
        return 'zimnii_peizazh'
      case 64:
      case 65:
      case 66:
        return 'zmeia_gigantskaia_iadovitaia '
      case 82:
        return 'zmeia_gigantskii_udav'
      case 24:
      case 25:
      case 26:
        return 'izumrudnyi_anclav'
      case 29:
      case 30:
      case 31:
        return 'issledovateli'
      case 27:
      case 28:
        return 'issledovatel_myortvyi'
      case 55:
      case 56:
      case 57:
        return 'kamadany'
      case 63:
        return 'krasnyi_volshebnik'
      case 74:
      case 75:
        return 'krovopiitcy'
      case 40:
      case 41:
        return 'ledianye_velikany'
      case 39:
        return 'letaiushchie_zmei'
      case 37:
      case 38:
        return 'letaiushchie_obeziany`'
      case 9:
      case 10:
      case 11:
      case 12:
        return 'lozy_ubiitcy'
      case 58:
        return 'liudoiashchery`'
      case 92:
      case 93:
        return 'nezhit_zombi'
      case 85:
      case 86:
      case 87:
        return 'nezhit_skelety`'
      case 88:
      case 89:
        return 'nezhit_spektry`'
      case 90:
      case 91:
        return 'nezhit_umertvie'
      case 83:
      case 84:
        return 'nezhit_upyri'
      case 61:
        return 'nochnaia_karga'
      case 67:
      case 68:
        return 'pauki'
      case 34:
      case 35:
      case 36:
        return 'pylaiushchii_kulak'
      case 62:
        return 'redkoe_rastenie'
      case 76:
      case 77:
        return 'roi_letuchikh_myshei`'
      case 69:
      case 70:
      case 71:
      case 72:
      case 73:
        return 'statuia_ubtao'
      case 78:
        return 'tabaksi_ohotneyk'
      case 81:
        return 'troll'
      case 59:
      case 60:
        return 'tuman_bezumnoi_obeziany'
      case 32:
      case 33:
        return 'tcepovaia_ulitka'
      case 20:
      case 21:
        return 'tciclopy`'
      case 19:
        return 'chvinga'
      case 4:
      case 5:
      case 6:
        return 'chelovekoobraznye_obeziany`'
      case 15:
      case 16:
      case 17:
      case 18:
        return 'ekspeditcionnyi_zapas'
      case 97:
      case 98:
        return 'iuan_ti'
    }
  },
  swamp: dice => {
    switch (dice) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return 'aldani'
      case 11:
        return 'artus_simber'
      case 59:
      case 60:
        return 'gigantskaia_shchyolkaiushchaia_cherepaha'
      case 54:
      case 55:
      case 56:
        return 'gigantskie_liagushki'
      case 61:
      case 62:
        return 'gigantskie_osy'
      case 57:
      case 58:
        return 'gigantskie_iashcheritcy'
      case 51:
      case 52:
      case 53:
        return 'gigantskii_krokodil'
      case 63:
      case 64:
        return 'grungi'
      case 22:
        return 'dinozavry_allozavr'
      case 23:
        return 'dinozavry_ankilozavr'
      case 24:
      case 25:
        return 'dinozavry_brontozavr'
      case 31:
      case 32:
      case 33:
        return 'dinozavry_gadrozavr'
      case 26:
      case 27:
      case 28:
      case 29:
      case 30:
        return 'dinozavry_dimetrodon'
      case 34:
      case 35:
        return 'dinozavry_pteranodon'
      case 99:
        return 'zhyoltaia_muskusnaia_liana_i_zombi'
      case 77:
      case 78:
      case 79:
      case 80:
        return 'zmeia_udav'
      case 42:
      case 43:
      case 44:
      case 45:
        return 'issledovateli'
      case 40:
      case 41:
        return 'issledovatel_myortvyi'
      case 86:
      case 87:
        return 'krovopiitcy'
      case 17:
      case 18:
      case 19:
      case 20:
      case 21:
        return 'krokodily'
      case 48:
      case 49:
      case 50:
        return 'letaiushchie_zmei'
      case 12:
      case 13:
      case 14:
        return 'lozy_ubiitcy'
      case 65:
      case 66:
        return 'liudoiashchery`'
      case 70:
        return 'mefity`'
      case 98:
        return 'nezhit_zombi'
      case 96:
      case 97:
        return 'nezhit_skelety`'
      case 95:
        return 'nezhit_upyri'
      case 71:
        return 'nochnaia_karga'
      case 73:
      case 74:
      case 75:
      case 76:
        return 'polzaiushchaia_nasyp'
      case 72:
        return 'redkoe_rastenie'
      case 88:
      case 89:
        return 'roi_letuchikh_myshei`'
      case 90:
      case 91:
      case 92:
      case 93:
      case 94:
        return 'roi_nasekomykh'
      case 83:
      case 84:
      case 85:
        return 'statuia_ubtao'
      case 67:
      case 68:
      case 69:
        return 'tuman_bezumnoi_obeziany'
      case 46:
      case 47:
        return 'tcepovaia_ulitka'
      case 15:
      case 16:
        return 'chvinga'
      case 36:
      case 37:
      case 38:
      case 39:
        return 'eblis'
      case 0:
      default:
        return 'iuan_ti'
    }
  },
  wasteland: dice => {
    switch (dice) {
      case 1:
        return 'artus_simber'
      case 38:
      case 39:
      case 40:
      case 41:
      case 42:
      case 43:
      case 44:
      case 45:
        return 'gigantskie_skorpiony'
      case 6:
      case 7:
      case 8:
      case 9:
        return 'drakon_krasnyi'
      case 99:
      case 0:
      default:
        return 'zhentarim'
      case 19:
        return 'issledovateli'
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
      case 18:
        return 'issledovatel_myortvyi'
      case 46:
      case 47:
      case 48:
      case 49:
      case 50:
      case 51:
      case 52:
      case 53:
      case 54:
        return 'magminy'
      case 55:
      case 56:
      case 57:
      case 58:
      case 59:
      case 60:
      case 61:
      case 62:
      case 63:
      case 64:
      case 65:
      case 66:
      case 67:
      case 68:
      case 69:
      case 70:
      case 71:
        return 'mefity`'
      case 98:
        return 'nezhit_zombi'
      case 86:
      case 87:
      case 88:
      case 89:
      case 90:
      case 91:
      case 92:
      case 93:
      case 94:
      case 95:
        return 'nezhit_skelety`'
      case 96:
      case 97:
        return 'nezhit_umertvie'
      case 84:
      case 85:
        return 'nezhit_upyri'
      case 72:
      case 73:
      case 74:
      case 75:
      case 76:
      case 77:
      case 78:
        return 'nochnaia_karga'
      case 20:
      case 21:
      case 22:
      case 23:
      case 24:
      case 25:
      case 26:
      case 27:
      case 28:
      case 29:
      case 30:
      case 31:
      case 32:
      case 33:
      case 34:
      case 35:
      case 36:
      case 37:
        return 'ognennye_tritony'
      case 79:
        return 'statuia_ubtao'
      case 80:
      case 81:
      case 82:
      case 83:
        return 'troll'
      case 2:
      case 3:
      case 4:
      case 5:
        return 'ekspeditcionnyi_zapas'
    }
  },
  omu_ruins: dice => {
    switch (dice) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return 'chelovekoobraznye_obeziany'
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return 'babuiny'
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
        return 'kannibaly'
      case 16:
      case 17:
      case 18:
      case 19:
      case 20:
        return 'drakon_volshebnyi_drakonchik'
      case 21:
      case 22:
      case 23:
      case 24:
      case 25:
        return 'gargoyles'
      case 26:
      case 27:
      case 28:
      case 29:
      case 30:
        return 'gigantskie_osy'
      case 31:
      case 32:
      case 33:
      case 34:
      case 35:
        return 'giant_wolf_spiders'
      case 36:
      case 37:
      case 38:
      case 39:
      case 40:
        return 'gobliny'
      case 41:
      case 42:
      case 43:
      case 44:
      case 45:
        return 'grungi'
      case 46:
      case 47:
      case 48:
      case 49:
      case 50:
        return 'king_of_feathers'
      case 51:
      case 52:
      case 53:
      case 54:
      case 55:
        return 'kobolds'
      case 56:
      case 57:
      case 58:
      case 59:
      case 60:
        return 'krasnyi_volshebnik'
      case 61:
      case 62:
      case 63:
      case 64:
      case 65:
        return 'polzaiushchaia_nasyp'
      case 66:
      case 67:
      case 68:
      case 69:
      case 70:
        return 'su_monstry'
      case 71:
      case 72:
      case 73:
      case 74:
      case 75:
        return 'tabaksi_ohotneyk'
      case 76:
      case 77:
      case 78:
      case 79:
      case 80:
        return 'nezhit_upyri'
      case 81:
      case 82:
      case 83:
      case 84:
      case 85:
        return 'nezhit_zombi'
      case 86:
      case 87:
      case 88:
      case 89:
      case 90:
        return 'vegepigmei'
      case 91:
      case 92:
      case 93:
      case 94:
      case 95:
        return 'zhyoltaia_muskusnaia_liana_i_zombi'
      case 96:
      case 97:
      case 98:
      case 99:
      case 0:
      default:
        return 'iuan_ti'
    }
  },
  omu_palace: dice => {
    switch (dice) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return 'babuiny'
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
        return 'drakon_volshebnyi_drakonchik'
      case 16:
      case 17:
      case 18:
      case 19:
      case 20:
      case 21:
      case 22:
      case 23:
      case 24:
      case 25:
        return 'gargoyles'
      case 26:
      case 27:
      case 28:
      case 29:
      case 30:
      case 31:
      case 32:
      case 33:
      case 34:
      case 35:
        return 'giant_wolf_spiders'
      case 36:
      case 37:
      case 38:
      case 39:
      case 40:
        return 'gobliny'
      case 41:
      case 42:
      case 43:
      case 44:
      case 45:
      case 46:
      case 47:
      case 48:
      case 49:
      case 50:
        return 'king_of_feathers'
      case 51:
      case 52:
      case 53:
      case 54:
      case 55:
      case 56:
      case 57:
      case 58:
      case 59:
      case 60:
        return 'kobolds'
      case 61:
      case 62:
      case 63:
      case 64:
      case 65:
      case 66:
      case 67:
      case 68:
      case 69:
      case 70:
        return 'krasnyi_volshebnik'
      case 71:
      case 72:
      case 73:
      case 74:
      case 75:
        return 'su_monstry'
      case 76:
      case 77:
      case 78:
      case 79:
      case 80:
        return 'tabaksi_ohotneyk'
      case 81:
      case 82:
      case 83:
      case 84:
      case 85:
      case 86:
      case 87:
      case 88:
      case 89:
      case 90:
        return 'zhyoltaia_muskusnaia_liana_i_zombi'
      case 91:
      case 92:
      case 93:
      case 94:
      case 95:
      case 96:
      case 97:
      case 98:
      case 99:
      case 0:
      default:
        return 'iuan_ti'
    }
  },
  omu_swamp: dice => {
    switch (dice) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return 'krokodily'
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
        return 'gargoyles'
      case 16:
      case 17:
      case 18:
      case 19:
      case 20:
      case 21:
      case 22:
      case 23:
      case 24:
      case 25:
        return 'gigantskie_osy'
      case 26:
      case 27:
      case 28:
      case 29:
      case 30:
      case 31:
      case 32:
      case 33:
      case 34:
      case 35:
        return 'grungi'
      case 36:
      case 37:
      case 38:
      case 39:
      case 40:
      case 41:
      case 42:
      case 43:
      case 44:
      case 45:
      case 46:
      case 47:
      case 48:
      case 49:
      case 50:
        return 'king_of_feathers'
      case 51:
      case 52:
      case 53:
      case 54:
      case 55:
      case 56:
      case 57:
      case 58:
      case 59:
      case 60:
        return 'polzaiushchaia_nasyp'
      case 61:
      case 62:
      case 63:
      case 64:
      case 65:
        return 'nezhit_upyri'
      case 66:
      case 67:
      case 68:
      case 69:
      case 70:
        return 'nezhit_zombi'
      case 71:
      case 72:
      case 73:
      case 74:
      case 75:
      case 76:
      case 77:
      case 78:
      case 79:
      case 80:
        return 'vegepigmei'
      case 81:
      case 82:
      case 83:
      case 84:
      case 85:
      case 86:
      case 87:
      case 88:
      case 89:
      case 90:
        return 'zhyoltaia_muskusnaia_liana_i_zombi'
      case 91:
      case 92:
      case 93:
      case 94:
      case 95:
      case 96:
      case 97:
      case 98:
      case 99:
      case 0:
      default:
        return 'iuan_ti'
    }
  },
  mezro_tier_1: dice => {
    switch (dice) {
      case 1:
      case 2:
      case 3:
        return 'mezro_apes'
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return 'mezro_crocodile_cultists'
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
        return 'mezro_crocodiles'
      case 16:
      case 17:
        return 'mezro_dino_nest'
      case 18:
      case 19:
      case 20:
        return 'mezro_explorers'
      case 21:
        return 'mezro_faerie_dragon'
      case 22:
      case 23:
      case 24:
      case 25:
        return 'mezro_flaming_fist_patrol'
      case 26:
      case 27:
        return 'letaiushchie_obeziany'
      case 28:
        return 'mezro_ghost'
      case 29:
      case 30:
      case 31:
      case 32:
      case 33:
      case 34:
      case 35:
        return 'mezro_ghouls'
      case 36:
      case 37:
        return 'mezro_giant_spiders'
      case 38:
      case 39:
        return 'mezro_vegepygmies'
      case 40:
      case 41:
        return 'mezro_goblins'
      case 42:
        return 'mezro_grung_hatchery'
      case 43:
      case 44:
        return 'mezro_grung_patrol'
      case 45:
        return 'mezro_mage_hunters'
      case 46:
      case 47:
      case 48:
        return 'mezro_mantraps'
      case 49:
        return 'mezro_mazewalker'
      case 50:
      case 51:
        return 'mezro_minotaurs'
      case 52:
      case 53:
      case 54:
      case 55:
      case 56:
      case 57:
      case 58:
      case 59:
      case 60:
        return 'mezro_skeletons'
      case 61:
      case 62:
      case 63:
        return 'mezro_supply_wagon'
      case 64:
      case 65:
        return 'mezro_yuan_ti_pureblood'
      case 66:
      case 67:
      case 68:
      case 69:
      case 70:
      case 71:
      case 72:
      case 73:
      case 74:
      case 75:
        return 'mezro_zombie_dinosaurs'
      case 76:
      case 77:
      case 78:
      case 79:
      case 80:
      case 81:
      case 82:
      case 83:
      case 84:
      case 85:
      case 86:
      case 87:
      case 88:
      case 89:
      case 90:
      case 91:
      case 92:
      case 93:
      case 94:
      case 95:
      case 96:
      case 97:
      case 98:
      case 99:
      case 0:
      default:
        return 'mezro_zombies'
    }
  },
  mezro_tier_2: dice => {
    switch (dice) {
      case 1:
      case 2:
        return 'mezro_apes'
      case 3:
      case 4:
        return 'mezro_cannibals'
      case 5:
      case 6:
      case 7:
      case 8:
        return 'mezro_crocodile_cultists'
      case 9:
      case 10:
        return 'mezro_crocodiles'
      case 11:
      case 12:
        return 'mezro_dino_ambush'
      case 13:
      case 14:
        return 'mezro_dino_nest'
      case 15:
      case 16:
        return 'mezro_explorers'
      case 17:
        return 'mezro_faerie_dragon'
      case 18:
      case 19:
      case 20:
        return 'mezro_flaming_fist_patrol'
      case 21:
        return 'mezro_ghost'
      case 22:
      case 23:
      case 24:
      case 25:
      case 26:
      case 27:
        return 'mezro_ghouls'
      case 28:
      case 29:
        return 'mezro_giant_spiders'
      case 30:
      case 31:
        return 'mezro_vegepygmies'
      case 32:
      case 33:
        return 'mezro_goblins'
      case 34:
        return 'mezro_grung_hatchery'
      case 35:
      case 36:
        return 'mezro_grung_patrol'
      case 37:
      case 38:
      case 39:
      case 40:
        return 'mezro_karanok'
      case 41:
      case 42:
        return 'mezro_living_traps'
      case 43:
        return 'mezro_mage_hunters'
      case 44:
      case 45:
        return 'mezro_mantraps'
      case 46:
        return 'mezro_mazewalker'
      case 47:
      case 48:
        return 'mezro_minotaurs'
      case 49:
      case 50:
        return 'mezro_shambling_mound'
      case 51:
      case 52:
      case 53:
      case 54:
      case 55:
      case 56:
        return 'mezro_skeletons'
      case 57:
      case 58:
        return 'mezro_golem'
      case 59:
      case 60:
        return 'mezro_supply_wagon'
      case 61:
      case 62:
      case 63:
      case 64:
        return 'mezro_wights'
      case 65:
      case 66:
        return 'mezro_yuan_ti_abomination'
      case 67:
      case 68:
        return 'mezro_yuan_ti_pureblood'
      case 69:
      case 70:
        return 'mezro_beholder_zombie'
      case 71:
      case 72:
      case 73:
      case 74:
      case 75:
      case 76:
      case 77:
      case 78:
      case 79:
      case 80:
        return 'mezro_zombie_dinosaurs'
      case 81:
      case 82:
      case 83:
      case 84:
      case 85:
      case 86:
      case 87:
      case 88:
      case 89:
      case 90:
      case 91:
      case 92:
      case 93:
      case 94:
      case 95:
      case 96:
      case 97:
      case 98:
      case 99:
      case 0:
      default:
        return 'mezro_zombies'
    }
  },
  mezro_tier_3: dice => {
    switch (dice) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return 'mezro_cannibals'
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return 'mezro_crocodile_cultists'
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
        return 'mezro_death_knight'
      case 16:
      case 17:
        return 'mezro_dino_ambush'
      case 18:
      case 19:
        return 'mezro_explorers'
      case 20:
        return 'mezro_faerie_dragon'
      case 21:
      case 22:
      case 23:
      case 24:
        return 'mezro_flaming_fist_patrol'
      case 25:
        return 'mezro_ghost'
      case 26:
      case 27:
      case 28:
      case 29:
      case 30:
      case 31:
      case 32:
        return 'mezro_ghouls'
      case 33:
      case 34:
        return 'mezro_grung_patrol'
      case 35:
      case 36:
      case 37:
      case 38:
      case 39:
      case 40:
      case 41:
        return 'mezro_karanok'
      case 42:
      case 43:
      case 44:
      case 45:
      case 46:
      case 47:
        return 'mezro_living_traps'
      case 48:
        return 'mezro_mage_hunters'
      case 49:
        return 'mezro_mazewalker'
      case 50:
      case 51:
        return 'mezro_minotaurs'
      case 52:
      case 53:
        return 'mezro_shambling_mound'
      case 54:
      case 55:
      case 56:
      case 57:
      case 58:
      case 59:
        return 'mezro_golem'
      case 60:
      case 61:
        return 'mezro_supply_wagon'
      case 62:
      case 63:
      case 64:
      case 65:
      case 66:
      case 67:
      case 68:
        return 'mezro_wights'
      case 69:
      case 70:
        return 'mezro_yuan_ti_abomination'
      case 71:
      case 72:
        return 'mezro_yuan_ti_pureblood'
      case 73:
      case 74:
      case 75:
        return 'mezro_beholder_zombie'
      case 76:
      case 77:
      case 78:
      case 79:
      case 80:
      case 81:
      case 82:
      case 83:
      case 84:
      case 85:
      case 86:
      case 87:
      case 88:
      case 89:
      case 90:
      case 91:
      case 92:
      case 93:
      case 94:
      case 95:
      case 96:
      case 97:
      case 98:
      case 99:
      case 0:
      default:
        return 'mezro_zombie_dinosaurs'
    }
  },
}

export default encounterByBiomeList
