import React from 'react'

import Layout from '@/components/Layout'
import Seo from '@/components/Seo'
import ToaEncounterGenerator from '@/components/ToaEncounterGenerator'

const ToaRandomEncounterGeneratorPage = () => (
  <Layout>
    <Seo
      title='Гробница аннигиляции: генератор случайных столкновений'
      description='Dungeons & Dragons, Гробница аннигиляции: генератор случайных столкновений'
    />
    <ToaEncounterGenerator/>
  </Layout>
)

export default ToaRandomEncounterGeneratorPage
