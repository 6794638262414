import {
  CREATURE_AARAKOCRA,
  CREATURE_BABOON,
  CREATURE_THORNY,
  CREATURE_VEGEPYGMY,
  CREATURE_WEREBOAR,
  CREATURE_WERETIGER,
  CREATURE_GARGOYLE,
  CREATURE_GIANT_WOLF_SPIDER,
  CREATURE_GIANT_BOAR,
  CREATURE_GIANT_FROG,
  CREATURE_GIANT_WASP,
  CREATURE_GIANT_SCORPION,
  CREATURE_GIANT_LIZARD,
  CREATURE_GIANT_CROCODILE,
  CREATURE_GIRALLON,
  CREATURE_GOBLIN,
  CREATURE_GOBLIN_BOSS,
  CREATURE_GRUNG,
  CREATURE_GRUNG_ELITE_WARRIOR,
  CREATURE_ALLOSAURUS,
  CREATURE_ANKYLOSAURUS,
  CREATURE_BRONTOSAURUS,
  CREATURE_VELOCIRAPTOR,
  CREATURE_HADROSAURUS,
  CREATURE_BOAR,
  CREATURE_DEINONYCHUS,
  CREATURE_DIMETRODON,
  CREATURE_QUETZALCOATLUS,
  CREATURE_PLESIOSAURUS,
  CREATURE_PTERANODON,
  CREATURE_STEGOSAURUS,
  CREATURE_TYRANNOSAURUS_REX,
  CREATURE_GIANT_APE,
  CREATURE_TRICERATOPS,
  CREATURE_GIANT_CONSTRICTOR_SNAKE,
  CREATURE_GHOUL,
  CREATURE_ZOMBIE,
  CREATURE_FAERIE_DRAGON_GREEN,
  CREATURE_DRAGON_RED_YOUNG,
  CREATURE_ASSASSIN,
  CREATURE_FLYING_SNAKE,
  CREATURE_PRIEST,
  CREATURE_THUG,
  CREATURE_TRIBAL_WARRIOR,
  CREATURE_GIANT_POISONOUS_SNAKE,
  CREATURE_CONSTRICTOR_SNAKE,
  CREATURE_SCOUT,
  CREATURE_MAGE,
  CREATURE_FIRENEWT_WARRIOR,
  CREATURE_KOBOLD,
  CREATURE_KOBOLD_INVENTOR,
  CREATURE_KOBOLD_SCALE_SORCERER,
  CREATURE_GUARD,
  CREATURE_SKELETON,
  CREATURE_STIRGE,
  CREATURE_CROCODILE,
  CREATURE_FROST_GIANT,
  CREATURE_WINTER_WOLF,
  CREATURE_GREEN_HAG,
  CREATURE_LIZARDFOLK,
  CREATURE_LIZARDFOLK_SHAMAN,
  CREATURE_MAGMIN,
  CREATURE_MAGMA_MEPHIT,
  CREATURE_MUD_MEPHIT,
  CREATURE_SMOKE_MEPHIT,
  CREATURE_STEAM_MEPHIT,
  CREATURE_SEA_HAG,
  CREATURE_OGRE_ZOMBIE,
  CREATURE_SPECTER,
  CREATURE_WIGHT,
  CREATURE_GHAST,
  CREATURE_NIGHT_HAG,
  CREATURE_FIRENEWT_WARLOCK_OF_IMIX,
  CREATURE_GIANT_STRIDER,
  CREATURE_GIANT_SPIDER,
  CREATURE_SHAMBLING_MOUND,
  CREATURE_KNIGHT,
  CREATURE_VETERAN,
  CREATURE_ACOLYTE,
  CREATURE_SWARM_OF_QUIPPERS,
  CREATURE_SWARM_OF_BATS,
  CREATURE_SWARM_OF_CENTIPEDES,
  CREATURE_SALAMANDER,
  CREATURE_FIRE_SNAKE,
  CREATURE_TROLL,
  CREATURE_TIGER,
  CREATURE_AXE_BEAK,
  CREATURE_FLAIL_SNAIL,
  CREATURE_CYCLOPS,
  CREATURE_APE,
  CREATURE_YUAN_TI_PUREBLOOD,
  CREATURE_YUAN_TI_MALISON_1,
  CREATURE_YUAN_TI_MALISON_2,
  CREATURE_YUAN_TI_MALISON_3,
  CREATURE_GLADIATOR,
  CREATURE_BEHOLDER_ZOMBIE,
  CREATURE_CULT_FANATIC,
  CREATURE_CULTIST,
  CREATURE_DEATH_KNIGHT,
  CREATURE_GHOST,
  CREATURE_APPRENTICE_WIZARD,
  CREATURE_MINOTAUR,
  CREATURE_BARLGURA,
  CREATURE_VEGEPYGMY_CHIEF,
  CREATURE_MINOTAUR_SKELETON,
  CREATURE_STONE_GOLEM,
  CREATURE_YUAN_TI_ABOMINATION,
  CREATURE_LIARA_PORTYR,
} from '@/constants/creatureIdList'
import {
  GOD_DENDAR,
} from '@/constants/godIdList'
import {
  SPELL_GLYPH_OF_WARDING,
  SPELL_FIND_THE_PATH,
} from '@/constants/spellIdList'
import {
  MAGIC_ITEM_FLAME_TONGUE,
} from '@/constants/magicItemIdList'
import {
  GEAR_CLIMBER_S_KIT,
  GEAR_CANOE,
  GEAR_NET,
  GEAR_RATIONS,
  GEAR_EXPLORER_S_PACK,
  GEAR_PRIEST_S_PACK,
  GEAR_TEJ_CASK,
} from '@/constants/gearIdList'

export default [
  {
    id: 'aarakokry',
    title: 'Ааракокры',
    description: `Любой персонаж с пассивной Мудростью (Восприятие) 15 или выше замечает 1к4+1 [ааракокр](CREATURE:${CREATURE_AARAKOCRA}), пролетающих над головой.
    
Эти существа — разведчики из Кир Сабала или иной области. Они наблюдают за партией с безопасного расстояния и не приближаются, если персонажи не демонстрируют мирные намерения. Люди-птицы дружелюбны и могут указать персонажам направление к близлежащим ориентирам.`,
  },
  // TODO: link ToA creatures
  {
    id: 'aldani',
    title: 'Алдани',
    description: `За персонажами скрытно наблюдают 1к4 алдани (см. приложение D), которых может заметить любой персонаж с пассивной Мудростью (Восприятие) 12 или выше.

Алдани не общаются с персонажами, если их не вынуждают это делать. Они не хотят сражаться и предложат персонажам взятку, чтобы те покинули их район.

Алдани согласятся помогать персонажам только в том случае, если им будет предложено что-то очень ценное взамен — например, предложение отогнать группу хищных динозавров, вторгшихся на их территорию.`,
  },
  // TODO: link ToA creatures
  {
    id: 'almirazh',
    title: 'Альмираж',
    description: `Любой персонаж с пассивной Мудростью (Восприятие) 12 или выше замечает 1к6 альмиражей (см. приложение D) на расстоянии 60 футов. Альмиражи убегают от любого существа, которого они могут видеть в пределах 30 футов от себя. Любой персонаж, успешно поймавший альмиража, может использовать действие, чтобы выполнить проверку Мудрости (Уход за животными) СЛ 14. Если проверка успешна, альмираж успокаивается и не атакует персонажа или не убегает, если ему не угрожает опасность, и он не пострадал.`,
  },
  // TODO: link ToA creatures
  {
    id: 'artus_simber',
    title: 'Артус Симбер',
    description: `Артуса Симбера (с или без его спутника, по имени Дрэгонбэйт) можно встретить почти везде.

Дополнительную информацию об этих НИП см. в приложении D.

Персонажи могут наткнуться на лагерь Артуса вечером, или он может выйти на них. Они могут найти его в лагере другой группы исследователей или рассказывающем историю Дрэгонбэйту. Он мог появиться из ниоткуда, используя силы Кольца Зимы, чтобы помочь персонажам, оказавшимся в трудной ситуации.`,
  },
  {
    id: 'babuiny',
    title: 'Бабуины',
    description: `Группа 3к6 [бабуинов](CREATURE:${CREATURE_BABOON}) обрушивается на персонажей, вторгшихся на их территорию.

Бабуинов можно отвлечь, бросив каждому из них еды ([рационы на день](GEAR:${GEAR_RATIONS})). В противном случае они атакуют.`,
  },
  // TODO: link ToA creatures
  {
    id: 'vaiia_trekhsotcvetnaia',
    title: 'Вайя трехсоцветная',
    description: `Если это столкновение происходит во время движения персонажей, они набредают на 1к4 вайи трехсоцветных (см. приложение D), которые кажутся обычными растениями до тех пор, пока не нападут.

Если столкновение происходит во время отдыха в лагере, то растения пытаются проникнуть в лагерь, лишить персонажей сознания оранжевыми цветками, а затем убить их жёлтыми и красными цветками.`,
  },
  {
    id: 'vegepigmei',
    title: 'Вегепигмеи',
    description: `Персонажи встречаются с 1к4 [вегепигмеями](CREATURE:${CREATURE_VEGEPYGMY}), каждый из которых верхом на [колючке](CREATURE:${CREATURE_THORNY}).

Эти вегепигмеи-охотники далеко забрели от своего племени. Они убегут, если их превосходят численностью; в противном случае они атакуют.`,
  },
  {
    id: 'vervepr',
    title: 'Вервепрь',
    description: `[Вервепрь](CREATURE:${CREATURE_WEREBOAR}), замаскированный пол чультского священника, тупо смотрит на исследователей, вторгающихся на его территорию, и требует, чтобы персонажи ушли. На шее он носит деревянный святой символ Убтао (узор лабиринта, вырезанный на круглом диске).

Вервепрь может охранять святыню в Убтао, рощу деревьев вукка (см. приложение C) или пещеру, которую он использует как логово.

Существо может также владеть сокровищем в своём логове; бросьте к100 трижды, сверившись с таблицей [Выпавшие сокровища](/campaigns/toa/generator-treasures), чтобы определить, что персонажи смогут найти в логове.`,
  },
  {
    id: 'vertigr',
    title: 'Вертигр',
    description: `[Вертигр](CREATURE:${CREATURE_WERETIGER}) в человеческой форме предлагает сопроводить группу через особо опасное место дикой местности. У него нет скрытого мотива, и он не просит оплаты.

Если персонажи принимают его помощь, у них нет враждебных случайных встреч, пока вертигр с ними.

Он уходит после сопровождения группы в течение 24 часов или, когда достигает места, которое не хочет исследовать, включая Ому, Нангалор или Оролунга.`,
  },
  {
    id: 'gargoyles',
    title: 'Горгульи',
    description: `На скале расположились 2к4 [горгулий](CREATURE:${CREATURE_GARGOYLE}), которые устремляются вниз и атакуют. Двое из них несут между собой [сеть](GEAR:${GEAR_NET}).

Действием любая горгулья может использовать сеть, чтобы совершить рукопашную атаку оружием (+4 к попаданию) против одного Маленького или Среднего существа. Если атака попадает, горгульи поднимают персонажа в воздух и улетают с уловом. Для поднятия сетки, если в ней есть пойманное существо, требуются обе горгульи.

Горгульи убегут, если их численность сократить до половины от их первоначального количества.`,
  },
  {
    id: 'giant_wolf_spiders',
    title: 'Гигантские паук-волки',
    description: `На героев нападают 1к6 [гигантских паук-волков](CREATURE:${CREATURE_GIANT_WOLF_SPIDER}), живущие среди развалин в близлежащих руинах.

Поиск логова пауков может позволить найти в нём сокровище; бросьте к100 один раз, сверившись с таблицей [Выпавшие сокровища](/campaigns/toa/generator-treasures), чтобы определить какое сокровище найдут персонажи в логове.`,
  },
  // TODO: link ToA creatures
  {
    id: 'gigantskaia_shchyolkaiushchaia_cherepaha',
    title: 'Гигантская щёлкающая черепаха',
    description: `Персонажи видят гигантскую щёлкающую черепаху (см. приложение D), загорающую на берегу.

Черепаха атакует любого персонажа, которого может видеть в пределах 30 футов от себя.`,
  },
  {
    id: 'gigantskie_kabany',
    title: 'Гигантские кабаны',
    description: `Персонажи видят и слышат впереди 1к4 + 1 [гигантских кабанов](CREATURE:${CREATURE_GIANT_BOAR}).

Чтобы скрытно пройти мимо кабанов, не провоцируя их атаки, потребуется успешная групповая проверка Ловкости (Скрытность) СЛ 12.`,
  },
  {
    id: 'gigantskie_liagushki',
    title: 'Гигантские лягушки',
    description: `Эти 2к6 [гигантских лягушек](CREATURE:${CREATURE_GIANT_FROG}) собрались в кучу и пытаются съесть всё, что попадается им на глаза.

Персонажи могут услышать хлопающие звуки за спиной, когда амфибии приближаются к ним.`,
  },
  {
    id: 'gigantskie_osy',
    title: 'Гигантские осы',
    description: `Жужжащий звук возвещает о присутствии 1к6 [гигантских ос](CREATURE:${CREATURE_GIANT_WASP}), прежде чем персонажи их увидят.

Осы сразу атакуют, игнорируя тяжелобронированные цели в пользу тех, у кого лёгкий доспех или нет доспехов вовсе.

В конце сражения каждый персонаж, получивший ранения от гигантской осы, должен пройти испытание Телосложения СЛ 11 или заразиться дрожащей болезнью (см. «Болезни», стр. 40).`,
  },
  {
    id: 'gigantskie_skorpiony',
    title: 'Гигантские скорпионы',
    description: `Любой персонаж с пассивной Мудростью (Восприятие) 15 или выше замечает 1к3 [гигантских скорпионов](CREATURE:${CREATURE_GIANT_SCORPION}) за мгновение до того, как они выйдут из укрытия и нападут.

В конце сражения каждый персонаж, получивший ранения от гигантского скорпиона, должен пройти испытание Телосложения СЛ 11 или заразиться дрожащей болезнью (см. «Болезни», стр. 40).`,
  },
  {
    id: 'gigantskie_iashcheritcy',
    title: 'Гигантские ящерицы',
    description: `Персонажи натыкаются на 1к6 [гигантских ящериц](CREATURE:${CREATURE_GIANT_LIZARD}), загорающих на тёплых камнях.

Ящерицы не представляют угрозы, если на них не нападают, и они уже не подлежат дрессировке на роль вьючных животных.`,
  },
  {
    id: 'gigantskii_krokodil',
    title: 'Гигантский крокодил',
    description: `Прежде чем он выйдет из воды, пройдите [гигантским крокодилом](CREATURE:${CREATURE_GIANT_CROCODILE}) проверку Ловкости (Скрытность). Любой персонаж, чья пассивная Мудрость (Восприятие) меньше, чем результат проверки, захвачен врасплох, когда чудовищное существо атакует.`,
  },
  {
    id: 'girallony',
    title: 'Гираллоны',
    description: `Два [гираллона](CREATURE:${CREATURE_GIRALLON}) сидят на деревьях, жердях, на вершинах скал или деревянных балках среди руин, покрытых лозой. Персонажи замечают их автоматически и могут избежать столкновения, если они уйдут и преуспеют в проверке Ловкости (Скрытность) СЛ 13.

Если персонажи побеждают гираллонов или прогоняют их, то поиск в области позволяет найти спрятанный экспедиционный запас, определяемый случайным образом по таблице Экспедиционный запас.

У гираллонов также может быть некоторое сокровище, спрятанное рядом с экспедиционным запасом; бросьте к100 один, сверившись с таблицей [Выпавшие сокровища](/campaigns/toa/generator-treasures), чтобы узнать, найдено ли что-нибудь, после поиска в области.`,
  },
  {
    id: 'gobliny',
    title: 'Гоблины',
    description: `Типичная патрульная или охотничья группа гоблинов Батири состоит из [босса гоблинов](CREATURE:${CREATURE_GOBLIN_BOSS}), командующего 2к6 + 3 [гоблинами](CREATURE:${CREATURE_GOBLIN}), на них всех одеты деревянные маски. Они спокойно ходят по знакомым районам и редко выходят за пределы своей родной территории, Батири предпочитают охотиться ночью и редко устраивают засады в течение дня.

Ночное столкновение с гоблинами Батири связано с нападением на лагерь персонажей. Каждый член отряда, стоящий на страже ночью, должен попытаться пройти проверку Мудрости (Восприятие) СЛ 16, сделанную с помехой из-за ночного шума в джунглях. При успехе персонаж обнаруживает, что гоблины приближаются к лагерю и собираются атаковать, а также он может разбудить остальную часть группы. Если в проверке никто не преуспевает, то всех персонажей застали врасплох.

Если это столкновение происходит пока персонажи путешествуют в течение дня, то каждый участник группы совершит проверку Мудрости (Восприятие или Выживание) СЛ 16, чтобы обнаружить признаки засады: невыгодная местность в сочетании с жуткой тишиной, ненормальной для джунглей.

Гоблины торгуются за свою жизнь, если их схватили. Персонажи могут заставить или принудить к сотрудничеству пойманных гоблинов, в таком случае Батири будут служить проводниками. У них есть дополнительный навык: Выживание +1.`,
  },
  {
    id: 'grungi',
    title: 'Грунги',
    description: `Охотничья группа состоит из 2к6 [грунгов](CREATURE:${CREATURE_GRUNG}) во главе с [элитным воином грунгов](CREATURE:${CREATURE_GRUNG_ELITE_WARRIOR}). Если это столкновение происходит во время движения персонажей, то грунги устроили засаду на деревьях. Любой персонаж с пассивной Мудростью (Восприятие) 15 или выше замечает их в последний момент.

Всех остальных персонажей застали врасплох. Если это столкновение происходит, когда группа находится в лагере, то грунги заметили персонажей ещё днём, а затем тайком проследили за ними. Каждый персонаж, стоящий на страже, должен преуспеть в проверке Мудрости (Восприятие) СЛ 14, сделанную с помехой из-за ночного шума в джунглях. При успехе персонаж обнаруживает вторжение и может разбудить остальную часть группы. Если в проверке никто не преуспевает, то всех персонажей застали врасплох.

Если персонажам удаётся поймать одного или несколько грунгов, то лягушонок предлагает привести персонажей к сокровищам в обмен на обещание свободы. «Сокровище» находится в полумиле от текущего местоположения группы и состоит из экспедиционного запаса, который Вы можете случайным образом определить по таблице Экспедиционный запас.`,
  },
  // TODO: link ToA creatures
  {
    id: 'dvarfy_albinos',
    title: 'Дварфы-альбинос',
    description: `Персонажи попадают в засаду 1к4 + 3 дварфов-альбиносов воинов (см. приложение D), которые скрытно атакуют из нор. Любой персонаж с пассивной Мудростью (Восприятие) 13 или выше замечает дварфов, но все остальные захвачены врасплох.

Дварфы обезвреживают персонажей, а не убивая их, крадут пищу, воду и снаряжение у тех, кто оказался без сознания. Они прекращают свою атаку, если кто-то из персонажей говорит с ними на Дварфийском или демонстрирует мирные намерения.`,
  },
  // TODO: link ToA creatures
  {
    id: 'dzhakuli',
    title: 'Джакули',
    description: `Неожиданно 1к6 джакули (см. приложение D) нападают на группу с деревьев.

Любой персонаж с пассивной Мудростью (Восприятие) 15 или выше способен среагировать на это, но всех остальных застали врасплох.`,
  },
  {
    id: 'dinozavry_allozavr',
    title: 'Динозавры, аллозавр',
    description: `Запах группы привлекает 1к3 [аллозавров](CREATURE:${CREATURE_ALLOSAURUS}), которых персонажи сначала замечают на расстоянии 100 ярдов от себя.

Любой персонаж, преуспевший в проверке Мудрости (Выживание) СЛ 15, может найти безопасное убежище (высокая ветка, небольшая расщелина, полое бревно и т. д.), до которого хищники не смогут добраться или проникнуть.`,
  },
  {
    id: 'dinozavry_ankilozavr',
    title: 'Динозавры, анкилозавр',
    description: `[Анкилозавр](CREATURE:${CREATURE_ANKYLOSAURUS}) питается растениями, но он атакует любых персонажей, которые ему мешают.`,
  },
  {
    id: 'dinozavry_brontozavr',
    title: 'Динозавры, бронтозавр',
    description: `Одинокий [бронтозавр](CREATURE:${CREATURE_BRONTOSAURUS}) приближается к персонажам, не обращая внимания на их присутствие. Хотя он может наступить на персонажей, которые не уйдут с его пути, он станет сражаться только в целях самозащиты.`,
  },
  {
    id: 'dinozavry_velotciraptor',
    title: 'Динозавры, велоцираптор',
    description: `Стая из 3к6 [велоцирапторов](CREATURE:${CREATURE_VELOCIRAPTOR}) выпрыгивает из укрытия и атакует.

Сделайте за динозавров одну проверку Ловкости (Скрытность), с преимуществом. Любой персонаж, чья пассивная Мудрость (Восприятие) равна или превышает результат проверки велоцирапторов, не удивлён их появлению.`,
  },
  {
    id: 'dinozavry_gadrozavr',
    title: 'Динозавры, гадрозавр',
    description: `Здесь пасётся 3к6 [гадрозавров](CREATURE:${CREATURE_HADROSAURUS}), среди которых также видны 1к6 молодых особей (не боевых).

Взрослые не атакуют, если их не нападают или не проявляют враждебность. Молодые — маленькие звери, которые могут быть проданы Ифану Талро’a в Порту Нянзару по 50 зм каждый или за 100 зм, если персонаж успешно пройдёт проверку Харизмы (Убеждение) СЛ 15, чтобы сторговаться.

С молодыми динозаврами легко справиться, если их отделить от родителей, но взрослые сражаются, если кто-то пытается захватить их молодняк.`,
  },
  {
    id: 'dinozavry_deinonikh',
    title: 'Динозавры, дейноних',
    description: `Дикий [кабан](CREATURE:${CREATURE_BOAR}) пробегает мимо группы, за которым следует охотничья стая 1к4 + 2 [дейнонихов](CREATURE:${CREATURE_DEINONYCHUS}).

Хищники решают, что персонажи — более интересная добыча.`,
  },
  {
    id: 'dinozavry_dimetrodon',
    title: 'Динозавры, диметродон',
    description: `Стая 2к6 [диметродонов](CREATURE:${CREATURE_DIMETRODON}) могут быть замечены вдоль солнечного берега реки или на камнях у воды.

Бросьте любой кубик. При нечётном результате диметродоны не обращают внимания на персонажей; при чётном результате они голодны и атакуют при малейшей провокации, или если персонажи оказываются в пределах 100 футов от них.`,
  },
  {
    id: 'dinozavry_kettcalkoatl',
    title: 'Динозавры, кетцалькоатль',
    description: `Персонажи замечают над головой 1к4 + 1 [кетцалькоатлей](CREATURE:${CREATURE_QUETZALCOATLUS}).

Они держат дистанцию и атакуют только в случае угрозы.`,
  },
  {
    id: 'dinozavry_pleziozavr',
    title: 'Динозавры, плезиозавр',
    description: `Два [плезиозавра](CREATURE:${CREATURE_PLESIOSAURUS}) сражаются за мёртвого гигантского осьминога.

Если персонажи сплавляются по реке, то существ можно заметить с расстояния 300 футов, после чего существа грозно рычат и атакуют группу. Чтобы направить каноэ к безопасному месту на берегу реки, один из персонажей, находящихся в каноэ, должен преуспеть в проверке Силы (Атлетика) СЛ 13, в то время, как другой персонаж в этом каноэ использует действие «Помощь».

Каноэ, которые не доходят до берега, переворачиваются и уничтожаются рептилиями, которые затем атакуют пловцов в воде.`,
  },
  {
    id: 'dinozavry_pteranodon',
    title: 'Динозавры, птеранодон',
    description: `Персонажи замечают над головой стаю [птеранодонов](CREATURE:${CREATURE_PTERANODON}), состоящую из 2к6 особей.

Они держат дистанцию и атакуют только в случае угрозы.`,
  },
  {
    id: 'dinozavry_stegozavr',
    title: 'Динозавры, стегозавр',
    description: ` Этот одинокий [стегозавр](CREATURE:${CREATURE_STEGOSAURUS}) настроен дружелюбно.

Он подходит к персонажам из любопытства, но любой, кто прикасается к нему провоцирует сильный удар хвостом.`,
  },
  {
    id: 'dinozavry_tirannozavr',
    title: 'Динозавры, тираннозавр',
    description: `Персонажи замечают [тираннозавра рекса](CREATURE:${CREATURE_TYRANNOSAURUS_REX}) в 300 ярдах от себя.

Есть 50% шанс, что голодное чудище сражается со [стегозавром](CREATURE:${CREATURE_STEGOSAURUS}), [трицератопсом](CREATURE:${CREATURE_TRICERATOPS}), парой [гигантских удавов](CREATURE:${CREATURE_GIANT_CONSTRICTOR_SNAKE}), [гигантской обезьяной](CREATURE:${CREATURE_GIANT_APE}) или с толпой [гулей](CREATURE:${CREATURE_GHOUL}) и [зомби](CREATURE:${CREATURE_ZOMBIE}). Ни одно из этих существ добровольно не объединится с группой, но их присутствие может ослабить тираннозавра до такой степени, что у персонажей появится шанс убить его.

Персонажи могут избежать столкновения с тираннозавром, если они держатся на расстоянии и успешно пройдут групповую проверку Ловкости (Скрытность) СЛ 15. Если какой-либо персонаж владеет навыком Выживание, то все персонажи проходят проверку с преимуществом.`,
  },
  {
    id: 'dinozavry_tritceratops',
    title: 'Динозавры, трицератопс',
    description: `Персонажи замечают одинокого [трицератопса](CREATURE:${CREATURE_TRICERATOPS}), пасущегося неподалёку.

Это самка и её гнездо находится совсем рядом, в гнезде находится один детёныш и два неразбитых яйца. Мать подозрительно смотрит на персонажей, и нападает только, если они окажутся между ней и гнездом.

Ифан Талро’a в Порту Нянзару предлагает 50 зм за детёныша или нетронутое яйцо трицератопса, или 150 зм, если персонаж успешно пройдёт проверку Харизмы (Убеждение) СЛ 15, чтобы сторговаться.`,
  },
  {
    id: 'drakon_volshebnyi_drakonchik',
    title: 'Дракон, волшебный дракончик',
    description: `Персонажи привлекли внимание невидимого, [зелёного волшебного дракончика](CREATURE:${CREATURE_FAERIE_DRAGON_GREEN}), и он следует за ними некоторое время.

Если персонажи в хорошем настроении, то крошечный дракон устраивает безобидные шалости во время их следующего короткого или длинного отдыха.

Если персонажи не разозлились из-за этих шалостей, то дракон появляется и правдиво отвечает на три своих вопроса перед тем, как попрощаться с партией. Если персонажи кажутся мрачными или злыми, или если шалости волшебного дракончика злят их, он улетает, не оказывая никакой помощи.

Волшебный дракончик недавно посетил Ому и знает о злых горгульях и «змелюдях», которые наблюдают за городом. Он также знает о других близлежащих достопримечательностей или местах, которые Вы определите сами.`,
  },
  {
    id: 'drakon_krasnyi',
    title: 'Дракон, красный',
    description: `Если персонажи находятся в радиусе 50 миль от шахты Сердце Вирма (см. главу 2), они видят [молодую красную драконицу](CREATURE:${CREATURE_DRAGON_RED_YOUNG}) известную как Тзинделор или Искра, которая кружится над этим местом.

Если они находятся дальше, то видят, как она летит в сторону этой шахты. Дракон игнорирует персонажей, если они не делают что-то, что привлечёт его внимание.`,
  },
  // TODO: link ToA creatures
  {
    id: 'zhyoltaia_muskusnaia_liana_i_zombi',
    title: 'Жёлтая мускусная лиана и зомби',
    description: `Персонажи проходят мимо руин, населённых 3к6 жёлтыми мускусными зомби (см. приложение D). Зомби могут быть распределены по всей области или сгруппированы вместе, в зависимости от местности. В самом сердце руин жёлтая мускусная лиана (см. приложение D) прицепилась к разрушенной арке, статуи или колодцу.

Если это столкновение происходит во время отдыха в лагере, зомби выходят из соседних руин, чтобы атаковать лагерь и пытаются довести персонажей до без сознательного состояния. Затем они утаскивают этих персонажей обратно руины к жёлтой мускусной лиане.`,
  },
  {
    id: 'zhentarim',
    title: 'Жентарим',
    description: `[Наёмный убийца](CREATURE:${CREATURE_ASSASSIN}) Жентарима с [летающей змеёй](CREATURE:${CREATURE_FLYING_SNAKE}) ведёт [священника](CREATURE:${CREATURE_PRIEST}), 2к6 [головорезов](CREATURE:${CREATURE_THUG}) и 1к6 [воителей племени](CREATURE:${CREATURE_TRIBAL_WARRIOR}) через дикую местность в поисках Артуса Симбера и Кольца Зимы. Если Артус с персонажами, Жентаримцы требуют кольцо и атакуют, если быстро его не получают. В противном случае они не проявляют большого интереса к персонажам.

Один раз бросьте к100, сверившись с таблицей «Выпавшие сокровища», чтобы узнать, есть ли у Жентаримцев какие-нибудь сокровища.`,
  },
  {
    id: 'zimnii_peizazh',
    title: 'Зимний пейзаж',
    description: `Персонажи натыкаются на чудесное зрелище: сфера зимней погоды радиусом 120 футов.

Чтобы прогнать некоторых монстров, Артус Симбер (см. приложение D) создал эту сферу, используя Кольцо Зимы. Все растения и поверхности в пределах сферы покрыты сверкающим льдом и инеем, а температура в сфере -35 °C. Эффект был создан артефактом и не может быть развеян.`,
  },
  {
    id: 'zmeia_gigantskaia_iadovitaia ',
    title: 'Змея, гигантская ядовитая ',
    description: `[Гигантская ядовитая змея](CREATURE:${CREATURE_GIANT_POISONOUS_SNAKE}) выпрыгивает из подлеска, чтобы атаковать случайного персонажа.

Персонажа, на которого нацелена змея, застали врасплох, если его пассивная Мудрость (Восприятие) меньше 14.`,
  },
  {
    id: 'zmeia_gigantskii_udav',
    title: 'Змея, гигантский удав',
    description: `[Гигантский удав](CREATURE:${CREATURE_GIANT_CONSTRICTOR_SNAKE}) атакует случайного члена группы из укрытия.

Персонажа, на которого нацелена змея, застали врасплох, если его пассивная Мудрость (Восприятие) меньше 12.`,
  },
  {
    id: 'zmeia_udav',
    title: 'Змея, удав',
    description: `[Удав](CREATURE:${CREATURE_CONSTRICTOR_SNAKE}) атакует случайного члена группы из укрытия.
    
Персонажа, на которого нацелена змея, застали врасплох, если его пассивная Мудрость (Восприятие) меньше 12.`,
  },
  // TODO: link ToA creatures
  {
    id: 'zorbo',
    title: 'Зорбо',
    description: `Если это столкновение происходит пока персонажи находятся в пути, они обнаруживают 2к6 зорбо (см. приложение D) на деревьях вукка (см. приложение C).

Существа рычат и оскаливают зубы, если персонажи к ним подходят. Если персонажи действуют угрожающе, зорбо атакуют.

Если это столкновение происходит, когда партия отдыхают в лагере, голодные зорбо спрыгивают с окружающих деревьев и атакуют.`,
  },
  {
    id: 'izumrudnyi_anclav',
    title: 'Изумрудный Анклав',
    description: `Персонажи сталкиваются с группой разведчиков Изумрудного Анклава или натыкаются на одну из их застав. Выберите то, что лучше всего подходит для обстоятельств и местоположения. 

**Застава анклава.** Любой персонаж с пассивной Мудростью (Восприятие) 13 или выше замечает деревянную платформу на дереве. Платформа находится на высоте 2к6 x 5 футов над землёй, чтобы забраться на дерево потребуется успешная проверка Силы (Атлетика) СЛ 12. Платформа представляет собой квадрат со стороной 10 футов и достаточно прочная, чтобы выдержать шесть персонажей и их экипировку. 

**Разведчики анклава.** Группа встречает 1к4 + 1 членов Изумрудного Анклава, работающих над тем, чтобы избавить джунгли от угрозы нежити. Группа работает со [священником](CREATURE:${CREATURE_PRIEST}) Майликки, но все остальные участники — [разведчики](CREATURE:${CREATURE_SCOUT}). Если недавно один из авантюристов умер, то Вы можете использовать это столкновение, чтобы ввести нового персонажа: члена Изумрудного Анклава, кого-то спасенного разведчиками, или персонажа, нанявшего разведчиков в качестве проводников. 

Разведчики готовы обменяться информацией (на равной основе) с незлой группой. Персонажи также могут убедить разведчиков присоединиться к своей группе на срок до 3 дней, пройдя проверку Харизмы (Убеждение) СЛ 12. У разведчиков Изумрудного Анклава есть достаточно еды и воды, чтобы питаться, и у каждого разведчика есть [набор путешественника](GEAR:${GEAR_EXPLORER_S_PACK}). Священник владеет [набором священника](GEAR:${GEAR_PRIEST_S_PACK}).`,
  },
  {
    id: 'issledovateli',
    title: 'Исследователи',
    description: `Группа натыкается на другую группу исследователей, состоящую из [мага](CREATURE:${CREATURE_MAGE}), [священника](CREATURE:${CREATURE_PRIEST}), [разведчика](CREATURE:${CREATURE_SCOUT}) и 1к6 [воителей племени](CREATURE:${CREATURE_TRIBAL_WARRIOR}).

Бросьте к6, чтобы определить ситуацию в группе:

* 1-2. Исследователи потерялись и голодны.
* 3-4. Исследователи в хорошей форме, но их преследуют [огненные тритоны](CREATURE:${CREATURE_FIRENEWT_WARRIOR}), [упыри](CREATURE:${CREATURE_GHOUL}), [гоблины](CREATURE:${CREATURE_GOBLIN}) или [грунги](CREATURE:${CREATURE_GRUNG}) (см. соответствующую запись в этом приложении).
* 5. Исследователи здоровы и направляются к ближайшему ориентиру, желая исследовать его.
* 6. Исследователи здоровы и возвращаются в Порт Нянзару для отдыха и пополнения припасов.`,
  },
  {
    id: 'issledovatel_myortvyi',
    title: 'Исследователь, мёртвый',
    description: `Чульт усыпан трупами и костями тех, кто стал жертвой его ужасов.

Когда персонажи обнаруживают одну из таких жертв, бросьте к20 и сверьтесь с таблицей «Мёртвые исследователи», чтобы определить, кого они находят.

Затем один раз бросьте к100, сверившись с таблицей [Выпавшие сокровища](/campaigns/toa/generator-treasures), чтобы узнать, можно ли что-то найти на или вблизи останков.`,
  },
  // TODO: link ToA creatures
  {
    id: 'kamadany',
    title: 'Камаданы',
    description: `Партия попадает в засаду 1к2 камаданов (см. приложение D).

Любой персонаж с пассивной Мудростью (Восприятие) 15 или выше предупреждён об атаке, но всех остальных застали врасплох. Персонажи, которые справились с камаданами, могут обыскать местность в поисках их логова, успешно пройдя проверку Мудрости (Выживание) СЛ 15, они его обнаружат.

В логове камаданов есть 25% шанс найти 1к3 детёнышей, размером с домашних кошек. Пока их змеи ещё не выросли, они выглядят как леопарды. Ифан Талро’a в Порту Нянзару заплатит 150 зм за живого камадана, но успешная проверка Харизмы (Убеждение) СЛ 15 позволит повысить цену до 300 зм.`,
  },
  {
    id: 'kannibaly',
    title: 'Каннибалы',
    description: `Чультские каннибалы рыскают в джунглях небольшими группами, убивают и поедая [зомби](CREATURE:${CREATURE_ZOMBIE}), избегая более быстрой и опасной нежити.

Покинутые своими богами, каннибалы начали поклоняться [Дендар Ночной Змее](GOD:${GOD_DENDAR}) и воздавать почести Рас-Нси в обмен на его благосклонность и защиту. Они нарисовали синий треугольник (символ Раса Нси) на своих лбах, как доказательство их преданности, и, как известно, отправляются в Ому, чтобы охотиться и отдать дань.

Если это столкновение происходит в течение дня, персонажи обнаруживают 3к6 [воителей племени](CREATURE:${CREATURE_TRIBAL_WARRIOR}), питающихся гниющими остатками расчленённого зомби. Если персонажи не шумят и держатся на расстоянии, они могут уйти, пока каннибалы их не заметили.

Если это столкновение происходит ночью, 3к6 [воителей племени](CREATURE:${CREATURE_TRIBAL_WARRIOR}) пытаются проникнуть в лагерь авантюристов и убить их. Любой персонаж, стоящий на страже, при успешной проверке Мудрости (Восприятие) СЛ 10 замечает атакующих.`,
  },
  // TODO: link ToA creatures
  {
    id: 'kapkany',
    title: 'Капканы',
    description: `Персонажи попадают в западню 1к4 + 1 капканов (см. приложение D), которых невозможно обнаружить заранее, пока они не атакуют.

Растения расположены на расстоянии 10 футов друг от друга, так что только одно растение атакует в первый раунд. Остальные должны ждать, когда персонажи окажутся в пределах 5 футов от них.`,
  },
  {
    id: 'king_of_feathers',
    title: 'Король Перьев',
    description: `Персонажи сталкиваются с тираннозавром, известным как Король Перьев. См. главу 3 для получения дополнительной информации об этом уникальном звере.`,
  },
  {
    id: 'kobolds',
    title: 'Кобольды',
    description: `Двигаясь по руинам города или дворца, персонажи натыкаются на 1к4 + 1 [кобольдов](CREATURE:${CREATURE_KOBOLD}) во главе с [кобольдом-изобретателем](CREATURE:${CREATURE_KOBOLD_INVENTOR}).

Персонажи с пассивной Мудростью (Восприятие) 12 или выше замечают кобольдов, которые убегут, если к ним приближаются или атакуют. Они служат [кобольду чешуйчатому чародею](CREATURE:${CREATURE_KOBOLD_SCALE_SORCERER}) Какаролу (см. главу 3).

Они могут отправиться в одно из святилищ города, чтобы перезарядить свои ловушки, или они могут вернуться в своё логово с недавно обнаруженным сокровищем.

Бросьте к100 один раз, сверившись с таблицей [Выпавшие сокровища](/campaigns/toa/generator-treasures), чтобы определить какое сокровище обнаружили кобольды`,
  },
  {
    id: 'krasnyi_volshebnik',
    title: 'Красный Волшебник',
    description: `Эта группа состоит из Красного Волшебника (ЗЗ мужчина или женщина, человек, [мага](CREATURE:${CREATURE_MAGE}) Тэя), 1к6 [стражников](CREATURE:${CREATURE_GUARD}) и 1к6 [скелетов](CREATURE:${CREATURE_SKELETON}), все они подчиняются Валиндре Теневой Мантии (см. «Сердце Убтао» на стр. 72).

Если это столкновение происходит за пределами Ому, то эта группа держит путь в город.

Если столкновение происходит в Ому, Красный Волшебник ищет святыни в городе (см. главу 3).

Тэйцы не рвутся в бой; если поражение кажется неизбежным, Красный Волшебник сдаётся и предлагает грубую карту Чульта, обозначающую районы, занятые нежитью (см. карту 2.1). Персонажи могут использовать эту карту, чтобы избегать этих регионов.`,
  },
  {
    id: 'krovopiitcy',
    title: 'Кровопийцы',
    description: `Чульт богат пещерами, руинами и полыми брёвнами, в которых можно спрятаться.

В течение дня персонажи натыкаются на 2к6 [кровопийц](CREATURE:${CREATURE_STIRGE}), когда они перемещаются по джунглям. Ночью в лагерь группы слетается столько же кровопийц.`,
  },
  {
    id: 'krokodily',
    title: 'Крокодилы',
    description: `Любой персонаж с пассивной Мудростью (Восприятие) 12 или выше видит и слышит 1к4 + 1 [крокодилов](CREATURE:${CREATURE_CROCODILE}) за мгновение до того, как они атакуют; все остальные персонажи захвачены врасплох.

Крокодил может действием опрокинуть каноэ и сбросить тех, кто находился в нём, в воду, пройдя проверку Силы (Атлетика) СЛ 15.`,
  },
  {
    id: 'ledianye_velikany',
    title: 'Ледяные великаны',
    description: `Персонажи слышат звук шагов огромных существ, путешествующих по диким землям.

Если они последуют за шумом, они столкнулись с поисковой группой из трёх [ледяных великанов](CREATURE:${CREATURE_FROST_GIANT}), сопровождаемых 1к2 [полярными волками](CREATURE:${CREATURE_WINTER_WOLF}). Для каждого такого столкновения есть 20% шанс, что это поисковая группа Друфи (см. «Хвалспид», стр. 78).

Ледяные великаны главным образом обеспокоены поиском Кольца Зимы, и они могут помочь персонажам, которые могут предоставить им полезную информацию. Они немедленно атакуют персонажей, скрывающих информацию или угрожающих раскрыть присутствие великанов Пылающему Кулаку. Ледяные великаны не носят сокровища с собой, пока охотятся.`,
  },
  {
    id: 'letaiushchie_zmei',
    title: 'Летающие змеи',
    description: `Бросьте кубик.
* При чётном результате группа встречает одиночную [летающую змею](CREATURE:${CREATURE_FLYING_SNAKE}).
* При нечётном результат персонажи сталкиваются с 2к6 [летающими змеями](CREATURE:${CREATURE_FLYING_SNAKE}).

Эти змеи атакуют только если чувствуют угрозу. Летающая змея, которую успешно схватили, может быть поймана в мешок или другой мягкий контейнер. После 1 часа заключения змея успокаивается. Персонаж, преуспевший в проверке Мудрости (Уход за животными) СЛ 13, может вынуть успокоившуюся змею из мешка, не спровоцировав её атаки или побега.

Персонажи могут продать пойманных летающих змей Ифану Талро’a в Порту Нянзару. Он предлагает 25 зм за каждую змею, но персонаж может попробовать сторговаться, успешно пройдя проверку Харизмы (Убеждение) СЛ 15, тем самым повысив цену до 50 зм.`,
  },
  // TODO: link ToA creatures
  {
    id: 'letaiushchie_obeziany`',
    title: 'Летающие обезьяны',
    description: `Звук хлопающий крыльев предупреждает персонажей о приближении 3к6 летающих обезьян (см. приложение D), которые нападают на авантюристов волнами, хватая их за руки и ноги.

Каждый персонаж должен пройти испытание Ловкости СЛ 12 или пушистые воришки заберут у них часть снаряжения. Если это столкновение происходит около Мбала (см. главу 2), то обезьяны являются слугами [зелёной карги](CREATURE:${CREATURE_GREEN_HAG}) Нанни Пупу.

Эти общительные существа интересуются гуманоидами и почти не боятся их. Летающая обезьяна, которую успешно схватили, поймали в [сеть](GEAR:${GEAR_NET}) или каким-то иным образом предотвратили её побег, может быть обучена выполнять простые трюки персонажем, владеющим навыком _Уход за животными_, и потратившим несколько часов в день, работая с обезьяной. В конце 1 недели персонаж проходит проверку Мудрости (Уход за животными) СЛ 10. При успехе летающая обезьяна может выполнять простой трюк по команде (например, взять определённый объект или танцевать под музыку). Каждая обезьяна может научиться максимум 1к6 трюкам и может узнавать только один трюк в неделю.`,
  },
  // TODO: link ToA creatures
  {
    id: 'lozy_ubiitcy',
    title: 'Лозы-убийцы',
    description: `Персонажи случайно попадают в охотничьи угодья 1к3 лоз-убийц (см. приложение D).

Лозы неотличимы от обычных растений и не могут быть замечены проверками Мудрости (Восприятие). Однако, они корням получают питательные вещества из падали, поэтому персонажи могут почувствовать запах мёртвого тела поблизости.

Так как лозы-убийцы могут двигаться, столкновение с ними вечером или ночью может можно представить так: лозы заползают в лагерь персонажей и душат их, когда те спят.`,
  },
  {
    id: 'liudoiashchery`',
    title: 'Людоящеры',
    description: `Персонажи натыкаются на 2к4 [людоящеров](CREATURE:${CREATURE_LIZARDFOLK}) и [людоящера-шамана](CREATURE:${CREATURE_LIZARDFOLK_SHAMAN}).

Эти людоящеры принадлежат к племени или королевству в Долине Ужаса и могут быть успокоены пищей (один [сухпаёк](GEAR:${GEAR_RATIONS}) на каждого людоящера в группе). В противном случае они атакуют.`,
  },
  {
    id: 'magminy',
    title: 'Магмины',
    description: `На персонажей нападают 2к6 [магминов](CREATURE:${CREATURE_MAGMIN}), которые сбегут, если их численность сократить половины от первоначального количества.`,
  },
  {
    id: 'mefity`',
    title: 'Мефиты',
    description: `Персонажи могут столкнуться с группой [магмовых мефитов](CREATURE:${CREATURE_MAGMA_MEPHIT}), [грязевых мефитов](CREATURE:${CREATURE_MUD_MEPHIT}), [дымовых мефитов](CREATURE:${CREATURE_SMOKE_MEPHIT}) или [паровых мефитов](CREATURE:${CREATURE_STEAM_MEPHIT}) (каждая группа 2к6 особей).

Мефиты не атакуют, если они превосходят персонажей численностью в соотношении два к одному, но они и не хотят помогать, если только их самих не превосходят численностью`,
  },
  {
    id: 'morskaia_karga',
    title: 'Морская карга',
    description: `Персонажи сталкиваются с тремя [морскими каргами](CREATURE:${CREATURE_SEA_HAG}), состоящими в шабаше.

Их любимый трюк заключается в том, чтобы вытащить повреждённое или заброшенное [каноэ](GEAR:${GEAR_CANOE}) на берег реки и обставить всё так, как будто голодный или раненный исследователь, нуждающимся в помощи.

Если персонажи побеждают морских карг и обыскивают область, бросьте к100 трижды, сверившись с таблицей [Выпавшие сокровища](/campaigns/toa/generator-treasures), чтобы определить, что они найдут. Все сокровища карг сложено в гнилом деревянном сундуке.`,
  },
  // TODO: link ToA creatures
  {
    id: 'nezhit_zombi',
    title: 'Нежить, зомби',
    description: `Нежить распространяется по джунглям Чульта, как чума.

Изумрудный Анклав, Орден Перчатки и Пылающий Кулак пытаются сдержать эту угрозу, но их усилия на сегодняшний день не оправдались.

Персонажи улавливают запах смерти в воздухе и слышат приближение нежити, странствующей по джунглям. Бросьте к10, чтобы определить с чем столкнулись персонажи:

* 1-3. 3к6 [зомби](CREATURE:${CREATURE_ZOMBIE})
* 4-5. 1 анкилозавр зомби (см. приложение D)
* 6-7. 1к4 гираллонов зомби (см. приложение D)
* 8-9. 1к4 [огров зомби](CREATURE:${CREATURE_OGRE_ZOMBIE})
* 10. 1 тираннозавр зомби (см. приложение D)`,
  },
  // TODO: link ToA creatures
  {
    id: 'nezhit_zombi_additional',
    title: 'Нежить, зомби',
    description: `Нежить распространяется по джунглям Чульта, как чума.

Изумрудный Анклав, Орден Перчатки и Пылающий Кулак пытаются сдержать эту угрозу, но их усилия на сегодняшний день не оправдались.

Персонажи улавливают запах смерти в воздухе и слышат приближение нежити, странствующей по джунглям. Бросьте к100, чтобы определить с чем столкнулись персонажи:

* 01-04 3к6 [зомби](CREATURE:${CREATURE_ZOMBIE})
* 05-08 3к6 диметродон зомби
* 09-12 2к10 гадрозавр зомби
* 13-16 2к6 рой зомби немиколоптеров
* 17-20 3к6 птеранодон зомби
* 21-24 3к6 велоцираптор зомби
* 25-28 2к4 дейноних зомби
* 29-32 1к8 дилофозавра зомби
* 33-36 2к4 троодон зомби
* 37-40 1 аллозавр зомби
* 41-44 1к4 [огр зомби](CREATURE:${CREATURE_OGRE_ZOMBIE})
* 45-48 1к4 пахицефалозавр зомби
* 49-52 1к4 кетцалькоатль зомби
* 53-56 1к6 зеалораптор зомби
* 57-60 1 анкилозавр зомби
* 61-64 1к4 гираллон зомби
* 65-68 1 стегозавр зомби
* 69-72 1 бронтозавр зомби
* 73-76 1 теризинозавр зомби
* 77-80 1 трицератопс зомби
* 81-84 1 брахиозавр зомби
* 85-88 1 саркозух зомби
* 89-92 1 титанозавр зомби
* 93-96 1 тираннозавр зомби
* 97-00 1 гиганотозавр зомби`,
  },
  {
    id: 'nezhit_skelety`',
    title: 'Нежить, скелеты',
    description: `Персонажи сталкиваются с 3к6 [скелетами](CREATURE:${CREATURE_SKELETON}).

Если встреча происходит во время путешествия, скелеты либо лежат на земле, либо захоронены под ней, и они готовы восстать и напасть на проходящих мимо исследователей.

Если партия находится в лагере, скелеты набредают на лагерь и атакуют.`,
  },
  {
    id: 'nezhit_spektry`',
    title: 'Нежить, спектры',
    description: `Злой дух мёртвого исследователя стал [спектром](CREATURE:${CREATURE_SPECTER}), который нападает на группу.

Тело исследователя может быть найдено, если персонаж успешно пройдёт проверку Мудрости (Выживание) СЛ 13. 

Если персонажи находят тело, см. раздел «Исследователь, мёртвый» ранее в этом приложении, чтобы определить, что они находят.`,
  },
  {
    id: 'nezhit_umertvie',
    title: 'Нежить, умертвие',
    description: `Персонажи сталкиваются с [умертвием](CREATURE:${CREATURE_WIGHT}), который скрывался в джунглях Чульта ещё со времён Магической чумы.

Он питает вечную ненависть к чультам и всему, что связано с Убтао. Если в группе находится чульт или кто-то из персонажей носит священный символ Убтао, он атакует этих персонажей в первую очередь.`,
  },
  {
    id: 'nezhit_upyri',
    title: 'Нежить, упыри',
    description: `Любой персонаж с пассивной Мудростью (Восприятие) 15 или выше слышит и чувствует вонь группы упырей, состоящей из 2к6 [упырей](CREATURE:${CREATURE_GHOUL}) во главе с [вурдалаком](CREATURE:${CREATURE_GHAST}).

У вурдалака на лбу есть татуировка в виде синего треугольника — знак того, что он когда-то служил Рас Нси.`,
  },
  {
    id: 'nochnaia_karga',
    title: 'Ночная карга',
    description: `Одна из Сшитых Сестёр (см. главу 5) следует за группой, оставаясь на Эфирном Плане.

Во время следующего длинного отдыха группы [ночная карга](CREATURE:${CREATURE_NIGHT_HAG}) материализуется и берёт немного крови или волос у случайного персонажа, а затем возвращается на Эфирный План.`,
  },
  {
    id: 'ognennye_tritony',
    title: 'Огненные тритоны',
    description: `Небольшой патруль огненных тритонов состоит из 1к4 [огненных тритонов воинов](CREATURE:${CREATURE_FIRENEWT_WARRIOR}), верхом на [гигантских ходунах](CREATURE:${CREATURE_GIANT_STRIDER}).

Большой патруль состоит из 2к4 [огненных тритонов воинов](CREATURE:${CREATURE_FIRENEWT_WARRIOR}) и [огненного тритона колдуна Имикса](CREATURE:${CREATURE_FIRENEWT_WARLOCK_OF_IMIX}), все верхом на [гигантских ходунах](CREATURE:${CREATURE_GIANT_STRIDER}).

Огненные тритоны всегда стараются нанести завершающий удар так, чтобы лишить противника сознания, чтобы затем утащить к себе в логово для пыток и в качестве пищи.`,
  },
  {
    id: 'pauki',
    title: 'Пауки',
    description: `Гигантские пауки с лёгкостью скрываются в густых джунглях и болотах Чульта.

Любой персонаж с пассивной Мудростью (Восприятие) 15 или выше замечает паутину вовремя, чтобы успеть предупредить других персонажей о столкновении с 1к6 [гигантскими пауками](CREATURE:${CREATURE_GIANT_SPIDER}).

В противном случае пауки внезапно атакуют, когда член группы, идущий впереди, ошибается, попадая в липкую паутину и становится схваченным (СЛ высвобождения 12). Сотни детёнышей гигантских пауков ползают по паутине, но они безвредны.`,
  },
  {
    id: 'polzaiushchaia_nasyp',
    title: 'Ползающая насыпь',
    description: `Бросьте любой кубик, когда происходит столкновение.

При чётном результате персонажи слышат, как это существо тащится через грязь, прежде чем атаковать их.

При нечётном результате, [ползающая насыпь](CREATURE:${CREATURE_SHAMBLING_MOUND}) скрывается в растительности, где её может заметить любой персонаж с пассивной Мудростью (Восприятие) 15 или выше.`,
  },
  // TODO: link ToA creatures
  {
    id: 'pteraliudy`',
    title: 'Птералюды',
    description: `Паря в небе, 1к4 + 2 птералюдов (см. приложение D) наблюдают за каждым движением персонажей и ждут, пока они не попадут в неприятности.

В следующий раз, когда происходит случайное столкновение, птералюды использует преимущество отвлечения и атакуют с воздуха, метая копья в раненых персонажей, не ввязываясь в ближний бой.

Если они встретят сопротивление, птералюды улетят, но они могут перегруппироваться для последующей атаки, по вашему усмотрению.`,
  },
  {
    id: 'pylaiushchii_kulak',
    title: 'Пылающий Кулак',
    description: `Солдаты Пылающего Кулака знают опасности Чульта лучше, чем многие, и они не воспринимают дикие земли легкомысленно.

Типичный патруль состоит из [рыцаря](CREATURE:${CREATURE_KNIGHT}) или [ветерана](CREATURE:${CREATURE_VETERAN}), командующего [прислужником](CREATURE:${CREATURE_ACOLYTE}), [разведчиком](CREATURE:${CREATURE_SCOUT}) и 2к6 [стражниками](CREATURE:${CREATURE_GUARD}), а иногда их сопровождают 1к4 [дейнонихов](CREATURE:${CREATURE_DEINONYCHUS}), обученных сражаться и охотиться вместе со своими дрессировщиками.

Пылающий Кулак дружелюбен и полезен для персонажей, владеющих хартией исследователя, выданной [Лиарой Портир](CREATURE:${CREATURE_LIARA_PORTYR}), командующей Форта Белуриан.

Если у группы нет этого документа, патруль пытается конфисковать критическое снаряжение авантюристов, сказав что они смогут забрать его в Форте Белуриан, а также советуют получить надлежащую хартию, пока они находятся там.`,
  },
  {
    id: 'redkoe_rastenie',
    title: 'Редкое растение',
    description: `Персонажи находят одно или несколько необычных растений (см. приложение C), в зависимости от броска к6:

1. 2к6 фруктов танцующей обезьяны, растущих на дереве
2. Куст менги с 2к6 унциями листьев
3. 1к4 корней райях, растущих в земле
4. 4к6 ягод синда, растущих на кустах
5. Дерево вукка с 2к6 орехами вукка
6. 1к6 забоу, растущих на мёртвом дереве`,
  },
  {
    id: 'roi_kviperov',
    title: 'Рои квиперов',
    description: `Это столкновение происходит, когда 1к4 [роёв квиперов](CREATURE:${CREATURE_SWARM_OF_QUIPPERS}) замечают группу, но эти существа опасны, только если персонажи находятся в воде.

Существа, находящиеся на берегу или в [каноэ](GEAR:${GEAR_CANOE}), пребывают в безопасности, но в обоих случаях рои следуют за персонажами до тех пор, пока они не скрываются из поля зрения и не падают в воду.`,
  },
  {
    id: 'roi_letuchikh_myshei`',
    title: 'Рои летучих мышей',
    description: `Руины, полые деревья и скрытые пещеры могут служить домом для летучих мышей.

Это столкновение происходит, когда персонажи нарушают спокойствие 1к4 [роёв летучих мышей](CREATURE:${CREATURE_SWARM_OF_BATS}), которые стали неестественно агрессивными от кормления плотью нежити.`,
  },
  {
    id: 'roi_nasekomykh',
    title: 'Рои насекомых',
    description: `Персонажи окружены 1к4 [роями многоножек](CREATURE:${CREATURE_SWARM_OF_CENTIPEDES}).

В конце сражения каждый персонаж, получивший ранения от роя, должен пройти испытание Телосложения СЛ 11 или заразиться дрожащей болезнью (см. «Болезни», стр. 40).`,
  },
  {
    id: 'salamandra',
    title: 'Саламандра',
    description: `Персонажи видят [саламандру](CREATURE:${CREATURE_SALAMANDER}), ухаживающую за гнездом 1к6 [огненных змей](CREATURE:${CREATURE_FIRE_SNAKE}).

Саламандра не настроена на драку и атакует только для защиты себя и змей.`,
  },
  {
    id: 'statuia_ubtao',
    title: 'Статуя Убтао',
    description: `Любой персонаж с пассивной Мудростью (Восприятие) 15 или выше замечает статую высотой 10 футов, заросшую лианами.

Статуя изображает короля Чульта — образ Убтао. Бросьте к4, чтобы определить, что особенного в этой статуе:

1. Сокровище лежит у подножия статуи, оставленное там в качестве подношения каким-то существом из джунглей. Бросьте к100 один раз, сверившись с таблицей [Выпавшие сокровища](/campaigns/toa/generator-treasures), чтобы определить какое сокровище найдут персонажи. Если результат броска указывает на отсутствие сокровищ, персонажи находят бесполезные предметы, вырезанные из кости.
2. Черепа гоблинов, грунгов и су-монстров сложены вокруг основания статуи.
3. На статуе нанесена [Охранная руна](SPELL:${SPELL_GLYPH_OF_WARDING}). Чтобы обнаружить руну, персонаж, осматривающий статую, должен преуспеть в проверке Интеллекта (Расследование) СЛ 15. Если какоелибо существо касается статуи, руна взрывается магической энергией в сфере с радиусом 20 футов, с центром на статуе. Каждое существо в этой области должно пройти испытание Ловкости СЛ 14, получая 22 (5к8) урона звуком при провале, или половину этого урона при успехе. Раскат грома имеет 75% шанс привлечь [кровопийц](CREATURE:${CREATURE_STIRGE}) или [тролля](CREATURE:${CREATURE_TROLL}), скрывающихся поблизости (см. «Кровопийцы» и «Тролль» в этом приложении).
4. У статуи есть борозды, вырезанные на животе, которые образуют лабиринт. Любой персонаж, который изучает борозды и преуспевает на проверке Интеллекта СЛ 10, видит ясный путь через лабиринт. Этот персонаж затем получает силу действием наложить заклинание [Поиск пути](SPELL:${SPELL_FIND_THE_PATH}), без каких-либо компонентов, проведя своим пальцем по правильному пути лабиринта. После использования эта способность исчезает. Как только статуя предоставит такую возможность, она не сможет сделать это снова до следующего рассвета.`,
  },
  // TODO: link ToA creatures
  {
    id: 'su_monstry',
    title: 'Су-монстры',
    description: `Партия встречается с 1к4 + 1 су-монстрами (см. приложение D).

Су-монстры осторожно подходят, симулируя любопытство. Если им позволят приблизится, каждый су-монстр использует своё _Психическое сдавливание_ в надежде ошеломить приключенца, прежде чем атаковать его укусом и когтями.

Су-монстры убегут в свои логова на вершинах деревьев, если битва проходит не в их пользу. Су-монстры могут иметь сокровища, спрятанные на одном из деревьев; бросьте к100 один раз, сверившись с таблицей [Выпавшие сокровища](/campaigns/toa/generator-treasures), чтобы определить какое сокровище найдут персонажи.`,
  },
  // TODO: link ToA creatures
  {
    id: 'tabaksi_ohotneyk',
    title: 'Табакси-охотник',
    description: `Любой персонаж с пассивной Мудростью (Восприятие) 15 или выше замечает табакси-охотника (см. приложение D), наблюдающего за группой с расстояния в 300 футов.

Если табакси скроется из виду, он может временно следовать за персонажами, а затем внезапно появится, чтобы помочь им отбиться в тяжёлом бою или предупредить их об опасности поблизости.

Если эта встреча происходит в Ому, см. главу 3 для получения дополнительной информации о найденных там табакси-охотниках`,
  },
  {
    id: 'tigr',
    title: 'Тигр',
    description: `[Тигр](CREATURE:${CREATURE_TIGER}) затаился и ждёт группу, но любой персонаж с пассивной Мудростью (Восприятие) 15 или выше замечает тигра.

Если тигра не обнаружили, то он набрасывается на персонажа, который находится на расстоянии 40 футов от него. Тигр отступает, если он теряет более половины своих хитов.`,
  },
  {
    id: 'toporocliuvy`',
    title: 'Топороклювы',
    description: `Стая из 1к6 + 3 [топороклювов](CREATURE:${CREATURE_AXE_BEAK}) пробегает мимо персонажей.

Персонажи могут слышать, как птицы приближаются к ним, но ничего не видят сквозь плотный кустарник, пока топороклювы не выпрыгнут на них и не атакуют, сокрушая всех до, кого могут добраться.`,
  },
  {
    id: 'troll',
    title: 'Тролль',
    description: `Голодный [тролль](CREATURE:${CREATURE_TROLL}) выпрыгивает из джунглей, намереваясь съесть персонажей.`,
  },
  {
    id: 'tuman_bezumnoi_obeziany',
    title: 'Туман безумной обезьяны',
    description: `Синий туман окутывает группу, покрывая площадь 1к6 20-футовых квадратов.

Любой персонаж с пассивной Мудростью (Восприятие) 15 или выше замечает туман и может предупреждать других. 

Если столкновение происходит во время отдыха группы, туман надвигается на лагерь со скоростью 5 футов в раунд.

Персонажи, которые вступают в контакт с туманом, заражаются лихорадкой безумной обезьяны (см. «Болезни», стр. 40).`,
  },
  {
    id: 'tcepovaia_ulitka',
    title: 'Цеповая улитка',
    description: `Персонажи замечают слизистую тропу [цеповой улитки](CREATURE:${CREATURE_FLAIL_SNAIL}).

Если они захотят следовать по ней, то потребуется успешная проверка Мудрости (Выживание) СЛ 10, чтобы определить правильное направление, в котором уползла улитка.`,
  },
  {
    id: 'tciclopy`',
    title: 'Циклопы',
    description: `[Циклопы](CREATURE:${CREATURE_CYCLOPS}) идут домой, к месту недалеко от Бухты Щёлкающей Черепахи.

Они не желают драки, но любые неожиданные движения или враждебное отношение персонажей могут спровоцировать бой. Циклопы хорошо знают район вокруг озера Луо и западную часть Долины Ужаса, и в этих областях они никогда не видели ничего похожего на Ому.

Бросьте к100 дважды, сверившись с таблицей «[Выпавшие сокровища](/campaigns/toa/generator-treasures)», чтобы узнать, какие сокровища были у циклопов.`,
  },
  {
    id: 'chvinga',
    title: 'Чвинга',
    description: `Чвинга (см. приложение D) интересуется персонажами.

Он пытается украсть что-то ценное из неохраняемого снаряжения или [каноэ](GEAR:${GEAR_CANOE}), но персонаж с пассивной Мудростью (Восприятие) 17 или выше может заметить его.

Чвинга всегда оставляет что-то взамен: симпатичную ракушку, горсть орехов или необработанный драгоценный камень (10 зм).`,
  },
  {
    id: 'chelovekoobraznye_obeziany`',
    title: 'Человекообразные обезьяны',
    description: `Персонажи натыкаются на 2к4 [обезьян](CREATURE:${CREATURE_APE}), поедающих превосходные фрукты.

Обезьяны чувствуют угрозу и демонстрируют признаки защиты своей пищи.

Если персонажи сразу же медленно отступают, то обезьяны ничего не делают, но демонстрируют угрожающее поведение. В противном случае они атакуют.`,
  },
  // TODO: link ToA creatures
  {
    id: 'eblis',
    title: 'Эблис',
    description: `Если персонажи находятся в движении, когда происходит это столкновение, они натыкаются на 1к4 + 1 эблис (см. приложение D), живущие в хижинах, построенных на сваях над болотистым болотом или прудом.

Эблисы нападут на раненых или ослабленных персонажей. Но если столкнутся с сильной и хорошо вооружённой группой, то предложат информацию в обмен на драгоценные камни. За драгоценные камни, стоимостью 50 зм, они укажут персонажам направление к близлежащим ориентирам.

Если персонажи следуют этим указаниям, то у них есть преимущество при проверках, сделанных для достижения этих ориентиров (см. «Навигация», стр. 38). Если персонажи побеждают эблис и обыскивают хижины, для определения того, что эблис спрятали в своих хижинах бросьте к100 трижды, сверившись с таблицей «[Выпавшие сокровища](/campaigns/toa/generator-treasures)».

Если это столкновение происходит, когда партия находится в лагере, эблис пробираются в лагерь и пытаются утащить одного из персонажей.`,
  },
  {
    id: 'ekspeditcionnyi_zapas',
    title: 'Экспедиционный запас',
    description: `Отряд наткнулся на тайник с припасами, оставленный другими исследователями.

По нему невозможно сказать, живы ли все ещё и собираются ли вернуться те, кто его оставили, либо мертвы. Бросьте к20 и определите результат по таблице «Экспедиционный запас», чтобы определить, что именно нашли персонажи.`,
  },
  {
    id: 'iuan_ti',
    title: 'Юань-ти',
    description: `Патрули юань-ти состоят из 1к6 + 1 [чистокровных юань-ти](CREATURE:${CREATURE_YUAN_TI_PUREBLOOD}), которые держатся на расстоянии, пытаясь собрать информацию, чтобы вернуться к Расу Нси.

Юань-ти замаскированы, но любой персонаж, который преуспевает в проверке Мудрости (Восприятие) СЛ 13, сделанной с помехой, замечает змеиный народ, когда они уходят.

Если это столкновение происходит в радиусе 25 миль от Ому, то патруль состоит из 1к4 [юань-ти проклинателей (тип 1)](CREATURE:${CREATURE_YUAN_TI_MALISON_1}), и для их обнаружения требуется проверка Мудрости (Восприятие) СЛ 14. Если персонажи преследуют их, они превращаются в змей и исчезают в джунглях.`,
  },
  {
    id: 'mezro_apes',
    title: 'Обезьяны',
    description: `Отряд обезьян вопит из-за деревьев и атакует персонажей.

Обезьяны недавно обыскали винный погреб и напились тежа. Всякий раз, когда пьяная обезьяна промахивается атакой, она падает.

Для ранга 1 — 1к4 + 1 [обезьян](CREATURE:${CREATURE_APE}).

Для ранга 2к10 + 5 [обезьян](CREATURE:${CREATURE_APE}).

**Сокровище.** Разграбленный винный погреб находится совсем рядом. Пятнадцать [бочонков тежа](GEAR:${GEAR_TEJ_CASK}) остаются неповрежденными, в каждом — 5 галлонов тежа (8 см за бочонок). На полке стоит 5 бутылок марочного бренди Турами по 200 зм.`,
  },
  {
    id: 'mezro_cannibals',
    title: 'Каннибалы',
    description: `Группа каннибалов пришла в Мезро чтобы найти пропавшего бога Убтао. Раскрашенная воинша Ронду возглавляет этот женский отряд: она надеется завоевать благосклонность своего бога и стать бара. Каннибалы нападают на исследователей сразу, как только видят их.

Для ранга 1 — 1к4 + 2 [воинов племени](CREATURE:${CREATURE_TRIBAL_WARRIOR}) и [ветеран](CREATURE:${CREATURE_VETERAN}).

Для ранга 2 — 3к6 + 5 [воинов племени](CREATURE:${CREATURE_TRIBAL_WARRIOR}) и [гладиатор](CREATURE:${CREATURE_GLADIATOR}).

**Сокровище.** Ронду носит мешок ценностей, которые она забрала у других групп исследователей. Для определения их стоимости бросьте кубик по таблице «[Индивидуальные сокровища](/generator-loot)» из главы 7 Руководства Мастера.`,
  },
  {
    id: 'mezro_crocodile_cultists',
    title: 'Культисты крокодила',
    description: `Культисты крокодила устроили ловушку, ловя жертв для своего пророка. Персонажи с пассивным Восприятием 17 или выше замечают сеть на пути группы. Если её никто не замечает, то первый персонаж в группе ловится сетью и поднимается в воздух (чтобы вырваться — Сила сл 18).

Культисты прячутся поблизости. Они атакуют, только если у них есть преимущество, и пытаются захватить своих врагов, а не убивать их. Любые пленники доставляются Эмберону Коальскатлю в локацию 11.

* Для ранга 1 — 1к4 + 3 [культиста](CREATURE:${CREATURE_CULTIST}) и [фанатик культа](CREATURE:${CREATURE_CULT_FANATIC}). 
* Для ранга 2 добавьте 1к3 + 1 [ветерана](CREATURE:${CREATURE_VETERAN}).
* Для ранга 3 — удвойте количество врагов. 

**Сокровище.** Культисты носят золото и ценные украшения извне Мезро. Для определения их стоимости бросьте кубик по таблице «[Индивидуальные сокровища](/generator-loot)» из главы 7 Руководства Мастера и умножьте результат на 5.`,

  },
  {
    id: 'mezro_crocodiles',
    title: 'Крокодилы',
    description: `Несколько крокодилов валяются в грязи впереди. У одного из крокодилов в чешуе торчит богато украшенное остриё копья. Если персонаж заходит в грязь, чтобы схватить наконечник копья, крокодилы нападают.

* Для ранга 1 — 1к4 + 2 [крокодила](CREATURE:${CREATURE_CROCODILE}).
* Для ранга 2 — 1к6 + 5 и [гигантский крокодил](CREATURE:${CREATURE_GIANT_CROCODILE}).

**Сокровище.** Золотой наконечник копья с выгравированными лягушками стоит 250 зм.`,
  },
  {
    id: 'mezro_death_knight',
    title: 'Рыцарь смерти',
    description: `Впереди на крыше стоит [рыцарь смерти](CREATURE:${CREATURE_DEATH_KNIGHT}), одетый в гнилую робу.

Заметив группу, он вызывает четырех [вурдалаков](CREATURE:${CREATURE_GHAST}) из близлежащих зданий и атакует.

Это опальный бара Рас Т'фима. После того, как Убтао отнял у него силу, Т'фима использовал Кольцо Зимы, чтобы сохранить свое положение, пока Артус Кимбер не разоблачил его предательство. Когда Магическая Чума обрушилась на Чульт, Рас Т'фима стал рыцарем смерти и поклялся искупить свою вину перед Убтао. Его бессмертный долг — защищать святой город Мезро от осквернителей.

**Сокровище.** Рас T'фима владеет ятаганом [Язык пламени](MAGIC_ITEM:${MAGIC_ITEM_FLAME_TONGUE}) и носит драгоценные кольца на пальцах. Для определения их стоимости бросьте кубик по таблице «[Индивидуальные сокровища](/generator-loot)» из главы 7 Руководства Мастера и умножьте результат на 7. `,
  },
  {
    id: 'mezro_dino_ambush',
    title: 'Засада динозавров',
    description: `Персонажи встречают искалеченного бегемота, умирающего в грязи.

Хищные динозавры прячутся поблизости, используя бегемота как приманку, чтобы приманить добычу. Если персонажи приближаются к бегемоту, динозавры нападают из засады.

* Для ранга 2 — 2к4 + 5 [дейнонихов](CREATURE:${CREATURE_DEINONYCHUS}).
* Для ранга 3 — пара [тиранозавров](CREATURE:${CREATURE_TYRANNOSAURUS_REX}).`,
  },
  {
    id: 'mezro_dino_nest',
    title: 'Трицератопс',
    description: `[Трицератопс](CREATURE:${CREATURE_TRICERATOPS}) устроил логово на пути и четыре маленьких трицератопсика резвятся поблизости.

Когда группа приближается, попросите группу сделать проверку Мудрости (Восприятие) сл 10. При успехе персонажи издалека замечают маленьких трицератопсиков. При провале они подходят слишком близко и попадают в засаду защищающегося родителя.

**Сокровище.** В гнезде лежит 1к6 трицератопсовых яиц. Подходящий покупатель в порту Нянзару купит каждое яйцо за 50 зм.`,
  },
  {
    id: 'mezro_explorers',
    title: 'Исследователи',
    description: `Персонажи встречают другую группу исследователей руин: [мага](CREATURE:${CREATURE_MAGE}), [рыцаря](CREATURE:${CREATURE_KNIGHT}), [разведчика](CREATURE:${CREATURE_SCOUT}) и 1к4 [воинов племени](CREATURE:${CREATURE_TRIBAL_WARRIOR}).

Исследователи только что потеряли часть своих в живой ловушке (см. Приложение B) и хотят покинуть Мезро.

Они предупреждают группу держаться подальше от здания, где они встретили монстра.`,
  },
  {
    id: 'mezro_faerie_dragon',
    title: 'Волшебный дракончик',
    description: `Пустая перевёрнутая бамбуковая клетка лежит неподалёку. На ней видны небольшие отметины от укусов. Внутри в невидимости прячется Млечнохлыстка — [зелёная волшебная драконица](CREATURE:${CREATURE_FAERIE_DRAGON_GREEN}).

Если кто-то подойдёт близко, Млечнохлыстка использует _Благодушное дыхание_, чтобы одурманить его.

Если персонажи подружатся с Млечнохлысткой, она расскажет что её пленил энтропист из Дома Каранок. Её клетка упала с его пояса, когда он попал в засаду нежити. Млечнохлыстка может указать группе путь к убежищу Дома Каранок в Коллегии Волшебников (локация 9).`,
  },
  {
    id: 'mezro_flaming_fist_patrol',
    title: 'Патруль Пылающего Кулака',
    description: `Персонажи встречают патруль Пылающего Кулака. Если патруль замечает персонажей, то пытается поймать их и отвести их в Перспективу (локация 3). 

* Для ранга 1 — 2к8 + 3 [стражей](CREATURE:${CREATURE_GUARD}). 
* Для ранга 2 добавьте 2 [рыцарей](CREATURE:${CREATURE_KNIGHT}). 
* Для ранга 3 добавьте 2 [гладиатора](CREATURE:${CREATURE_GLADIATOR}). 

**Сокровище.** У солдат с собой есть немного золота извне Мезро. Для определения их стоимости бросьте кубик по таблице «[Индивидуальные сокровища](/generator-loot)» из главы 7 Руководства Мастера и умножьте результат на 6.`,
  },
  {
    id: 'mezro_ghost',
    title: 'Призрак',
    description: `Музыка доносится из дома впереди, и в окнах мерцает мягкий свет.

В мгновение ока здание снова становится тихим и разрушенным. [Призрак](CREATURE:${CREATURE_GHOST}) мезроанского купца часто посещает помещение. Он напрасно ожидает сына, который должен был прийти на церемонию своего совершеннолетия. Если игроки соглашаются помочь духу, то он просит их найти останки его сына и принести их.

**Сокровище.** Подарок купца сыну всё ещё лежит в его гробу. Для определения их стоимости бросьте кубик по таблице «Драгоценные камни или предметы искусства» таблицы «Сокровищница» из главы 7 Руководства Мастера`,
  },
  {
    id: 'mezro_ghouls',
    title: 'Упыри',
    description: `В соседнем подвале прячется стая упырей, одетых в рваные наряды воинов племени. Когда персонажи проходят мимо, упыри выскакивают из засады.

* Для ранга 1 - 1к3 + 1 [упырей](CREATURE:${CREATURE_GHOUL}).
* Для ранга 2 добавьте 1к4 + 3 [вурдалака](CREATURE:${CREATURE_GHAST}).
* Для ранга 3, добавьте [ночную каргу](CREATURE:${CREATURE_NIGHT_HAG}) и удвойте количество [упырей](CREATURE:${CREATURE_GHOUL})`,
  },
  {
    id: 'mezro_giant_spiders',
    title: 'Гигантские пауки',
    description: `Гигантские пауки спускаются с крыш, когда персонажи проходят под ними. Несколько (1к4) коконов с трупами исследователей свисают с паутины.

* Для ранга 1 — 1к3 + 1 [гигантских пауков](CREATURE:${CREATURE_GIANT_SPIDER}).
* Для ранга 1 — 2к6 + 5 [гигантских пауков](CREATURE:${CREATURE_GIANT_SPIDER}).

**Сокровище.** Бросьте кубик по таблице «Мёртвые исследователи» из Гробницы Аннигиляции, чтобы описать трупы. Бросьте кубик по таблице «[Выпавшие Сокровища](/campaigns/toa/generator-treasures)» оттуда же, чтобы определить, есть ли у каждого из трупов какое-либо сокровище.`,
  },
  {
    id: 'mezro_vegepygmies',
    title: 'Плесневики',
    description: `Впереди видны плесневики на гигантских осах.

Осы едят из кучи гниющих фруктов в тени мангового дерева. Если персонажи преуспевают в проверке Ловкости (Скрытность) сл 12, они могут проскользнуть мимо монстров, не будучи замеченными.

* Для ранга 1 — 1d3 + 1 [плесневик](CREATURE:${CREATURE_VEGEPYGMY}) на таком количестве [гигантских ос](CREATURE:${CREATURE_GIANT_WASP}).
* Для ранга 2 — 2к6 + 4 пар [плесневиков](CREATURE:${CREATURE_VEGEPYGMY}) и [ос](CREATURE:${CREATURE_GIANT_WASP}).`,
  },
  {
    id: 'mezro_goblins',
    title: 'Отряд гоблинов',
    description: `An elite company of Batiri goblins marches into Mezro to avenge their queen’s murder. Chained velociraptors serve them as primitive bloodhounds.

If the characters killed Queen Grabstab during chapter 2 of Tomb of Annihilation, her son Bang leads the warband. If not, the goblins hunt the Flaming Fist mercenaries who raided their village. Bang wears his mother’s flayed face over his war mask.

* Для ранга 1, тут 1к6 + 3 гоблинов, босс гоблинов, и два велоцираптора.
* Для ранга 2, тут 3к8 + 5 гоблинов, босс гоблинов, и 2к4 + 1 велоцирапторов.

**Сокровище.** Bang carries a silvered +1 rapier that he stole from a pirate. Every time the blade delivers a mortal wound, snatches of an elven song are heard. Bang believes this is the god Maglubiyet thanking him for his sacrifices. `,
  },
  {
    id: 'mezro_grung_hatchery',
    title: 'Садок грунгов',
    description: `A welter of slimy eggs float inside a flooded courtyard, watched over by a group of grungs. An enslaved ogre called Umok mucks out the hatchery. Umok was dumb already, but the grung poison used to subdue him has made him obedient to anyone’s commands. 

* Для ранга 1, there is one ogre and 1d4+4 grungs (see appendix C).
* Для ранга 2, add 1d4+2 grung elite warriors. 

**Сокровище.** The eggs aren’t valuable. One of the grungs wears a bandolier containing five potions of healing.`,
  },
  // TODO: link ToA creatures
  {
    id: 'mezro_grung_patrol',
    title: 'Патруль грунгов',
    description: `Патруль грунгов пробирается через болото. Его ведёт вождь Нопуглуу (No-POO-gloo). Они охотятся на крокодилов, и отмечают убийства на длинном шесте.

* Для ранга 1 — 1к6 + 3 [грунга](CREATURE:${CREATURE_GRUNG}) и 1 [элитный воин грунгов](CREATURE:${CREATURE_GRUNG_ELITE_WARRIOR}).
* Для ранга 2 добавьте 1к6 + 3 [грунгов](CREATURE:${CREATURE_GRUNG}) и 1d4 + 2 [элитных воина грунгов](CREATURE:${CREATURE_GRUNG_ELITE_WARRIOR}).
* Для ранга 3 — их добыча сложена в бамбуковая корзину на спине титанозавра.

**Сокровище.** Грунги носят золотые украшения. Для определения их стоимости бросьте кубик по таблице «[Индивидуальные сокровища](/generator-loot)» в главе 7 Руководства Мастера и умножьте результат на 5.`,
  },
  {
    id: 'mezro_karanok',
    title: 'Дом Каранок',
    description: `The group cross paths with an expeditionary patrol from House Karanok.

A priest of entropy leads a gang of grizzled mercenaries in search of clues to the true location of Mezro. Для ранга 2, there is one priest of entropy and 1d4+4 guards. Для ранга 3, add 1d4+2 veterans. 

**Сокровище.** The priest of entropy carries a sack of treasure purloined from elsewhere in Chult. To determine its value, roll once on Individual Treasure table in chapter 7 of the Dungeon Master’s Guide, and multiply the resulting wealth by eight. `,
  },
  {
    id: 'mezro_living_traps',
    title: 'Живая ловушка',
    description: `Персонажи слышат приглушённые крики с соседней улицы.

Если они проводят расследование, они замечают солдата Пылающего Кулака, чья нога застряла в медвежьей ловушке. На самом деле солдат мертв, а живая ловушка, которая убила его, управляет его трупом, чтобы заманить добычу поближе.

Для ранга 3 это может быть пара живых ловушек, работающих вместе.`,
  },
  {
    id: 'mezro_mage_hunters',
    title: 'Маголовы',
    description: `Группа встречает группу «маголовов»: принципиально-добрых чультских волшебников, которые путешествовуют из племени в племя, сражаясь со злом и возвращая волшебников-отступников обратно в общество. В этой группе есть 1 [маг](CREATURE:${CREATURE_MAGE}) и 6 [учеников-волшебников](CREATURE:${CREATURE_APPRENTICE_WIZARD}).

Большинство маголовов исчезли после Чумы Заклинаний, но эта группа хочет восстановить орден в его прежней силе. Во главе с магом Имари Джабу они вернулись в Мезро, чтобы разыскать мезроанскую Коллегию Волшебников (локация 9).

**Сокровище.** Имари Джабу владеет посохом роя насекомых. У остальных есть припасы и золотые украшения. Чтобы определить их ценность, бросьте один раз по таблице «[Индивидуальные сокровища](/generator-loot)» в главе 7 Руководства Мастера и умножьте результат на пять.`,
  },
  // TODO: link ToA creatures
  {
    id: 'mezro_mantraps',
    title: 'Капканы',
    description: `Земля под ногами рушится и персонажи попадают в полуподвальный погреб, заросший растительностью.

Внутри находятся 1к4 + 1 капкана и кости множества исследователей.

**Сокровище.** Ценные предметы снаряжения разбросаны по костям, включая [набор для скалолазания](GEAR:${GEAR_CLIMBER_S_KIT}), три зелья невидимости и мешочек с 1к6 гранатами стоимостью по 100 зм каждый.`,
  },
  {
    id: 'mezro_mazewalker',
    title: 'Лабиринтоходец',
    description: `Персонажи встречают древнего чультского [священника](CREATURE:${CREATURE_PRIEST}) Убтао по имени Ча Нкрумане.

В молодости Ча был «лабиринтоходцем»: представителем мезроанского духовенства, направлявшим своих сограждан через лабиринт жизни. Сейчас ему 120 лет, и он вернулся, чтобы умереть в барадо храма Убтао (локация 15).`,
  },
  {
    id: 'mezro_minotaurs',
    title: 'Минотавры',
    description: `После катаклизма лабиринтные улицы жилого квартала Мезро стали местом паломничества для минотавров.

* Для ранга 1 — 1к3 [минотавров](CREATURE:${CREATURE_MINOTAUR}).
* Для ранга 2 добавьте 1к3 + 3 [минотавра](CREATURE:${CREATURE_MINOTAUR}).
* Для ранга 3 добавьте 2 [демона Барлгура](CREATURE:${CREATURE_BARLGURA}).`,
  },
  {
    id: 'mezro_shambling_mound',
    title: 'Ползающая насыпь',
    description: `[Плесневик-вождь](CREATURE:${CREATURE_VEGEPYGMY_CHIEF}) едет на [ползающей насыпи](CREATURE:${CREATURE_SHAMBLING_MOUND}) через руины, сидя на бамбуковом седле.

* Для ранга 2, его сопровождают 2к6+2 [плесневиков](CREATURE:${CREATURE_VEGEPYGMY}).
* Для ранга 3 их вдвое больше.`,
  },
  {
    id: 'mezro_skeletons',
    title: 'Скелеты',
    description: `Одетые в доспехи из шкур скелеты набрасываются на группу. У каждого скелета на лбу нарисован синий треугольник. 

* Для ранга 1 — 2к4+3 [скелетов](CREATURE:${CREATURE_SKELETON}). 
* Для ранга 2 добавьте 1к4+2 [скелетов минотавров](CREATURE:${CREATURE_MINOTAUR_SKELETON}).`,
  },
  {
    id: 'mezro_golem',
    title: 'Каменный голем',
    description: `Поблизости оживает заросшая плющом статуя, оказывающаяся [каменным големом](CREATURE:${CREATURE_STONE_GOLEM}).

Для ранга 2 это 1к3 [каменных големов](CREATURE:${CREATURE_STONE_GOLEM}).

**Сокровище.** На туловище каждого голема есть огненно-жёлтый гиацинт стоимостью в 1000 зм.`,
  },
  {
    id: 'mezro_supply_wagon',
    title: 'Телега с добычей',
    description: `Телега с добычей едет по городу в сторону штаба Пылающего Кулака (Перспектива, локация 3). В закрытой телеге — фрукты и овощи, собранные в руинах. Её тянет обученный [трицератопс](CREATURE:${CREATURE_TRICERATOPS}). 

* Для ранга 1 её сопровождают 1к8 + 2 перчаток ([стражей](CREATURE:${CREATURE_GUARD})). 
* Для ранга 2 — добаьте 1 манипа ([рыцаря](CREATURE:${CREATURE_KNIGHT})). 
* Для ранга 3 — добавьте 1 [мага](CREATURE:${CREATURE_MAGE}). 

**Сокровище.** У наёмников с собой есть личные предметы: чультские украшения или украшенное оружие, принесённое ими извне Мезро. Для определения их стоимости бросьте кубик по таблице «[Индивидуальные сокровища](/generator-loot)» из главы 7 Руководства Мастера и умножьте результат на 3.`,
  },
  {
    id: 'mezro_wights',
    title: 'Умертвия',
    description: `Туман стелется по улицам, скрывая приближение группы нежити. У каждого существа на теле вытатуирован символ Раса Нси.

2. На ранге 2 это 1к6 + 3 [умертвий](CREATURE:${CREATURE_WIGHT}).
3. На ранге 3 это 3к6 + 3 [умертвий](CREATURE:${CREATURE_WIGHT}).`,
  },
  {
    id: 'mezro_yuan_ti_abomination',
    title: 'Отродье юань-ти',
    description: `[Отродье юань-ти](CREATURE:${CREATURE_YUAN_TI_ABOMINATION}) пролезло в город, чтобы найти в библиотеке Мезро информацию о [Дендар Ночной Змее](GOD:${GOD_DENDAR}).

3. На ранге 3 его сопровождает группа из 1к3 + 3 [проклинателей юань-ти](CREATURE:${CREATURE_YUAN_TI_MALISON_2}).

**Сокровище.** У юань-ти с собой есть змеевидное украшение. Для определения их стоимости бросьте кубик по таблице «Индивидуальные сокровища» из главы 7 Руководства Мастера и умножьте результат на 6.`,
  },
  {
    id: 'mezro_yuan_ti_pureblood',
    title: 'Нивоно',
    description: `Чульт, одетый в рваную одежду торговца, ковыляет, ведомый проклинателями юань-ти. Если персонажи его спасают, он встаёт на колени и униженно просит их взять его с собой. Нивоно — [чистокровный юань-ти](CREATURE:${CREATURE_YUAN_TI_PUREBLOOD}), посланный расом Нси, чтобы проникнуть в группу.

1. Для ранга 1 — один [проклинатель юань-ти](CREATURE:${CREATURE_YUAN_TI_MALISON_1}) охраняет Нивоно.
2. Для ранга 2 — 1d3 + 2 [проклинателя юань-ти](CREATURE:${CREATURE_YUAN_TI_MALISON_2}).
3. Для ранга 3 — 2d4 + 5 [проклинателей юань-ти](CREATURE:${CREATURE_YUAN_TI_MALISON_3}).`,
  },
  {
    id: 'mezro_beholder_zombie',
    title: 'Злобоглаз-зомби',
    description: `Перевёрнутая телега лежит в куче обломков посреди улицы. Ноги в латных ботинках торчат из-под телеги. Под телегой лежит мёртвый рыцарь. Вокруг летают мухи.

При ближайшем рассмотрении телега выглядит так, словно ее издалека швырнуло на рыцаря. Если персонажи поднимают телегу, то из-под неё вылезает [злобоглаз-зомби](CREATURE:${CREATURE_BEHOLDER_ZOMBIE}).

Для ранга 3 — бой привлекает 2к4+3 [умертвий](CREATURE:${CREATURE_WIGHT}).

**Сокровище.** Рыцарь носил Латы +1 и кошель со 131 зм.`,
  },
  // TODO: link ToA creatures
  {
    id: 'mezro_zombie_dinosaurs',
    title: 'Динозавры-зомби',
    description: `Персонажи встречают группу безмозглых динозавров-зомби.

1. Для ранга 1 — 1к3 зеалорапторов-зомби. 
2. Для ранга 2 — 2к4+2 зеалорапторов-зомби. 
3. Для ранга 3 — 2к4+2 трицератопсов-зомби.`,
  },
  {
    id: 'mezro_zombies',
    title: 'Зомби',
    description: `Персонажи встречают орду нежити. У каждого зомби на лбу нарисован синий треугольник.

1. Для ранга 1 — 2к4 + 4 [зомби](CREATURE:${CREATURE_ZOMBIE}).
2. Для ранга 2 добавьте 1к3 + 2 [огра-зомби](CREATURE:${CREATURE_OGRE_ZOMBIE}).`,
  },
]
